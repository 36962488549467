document.addEventListener(
  "DOMContentLoaded",
  function (): void {
    require('./typescript/iykyk')
    require('./typescript/aos-animation')
    require('./typescript/main');
    require('./typescript/parallax')
    require('./typescript/mobile-menu')
    require('./typescript/latest-opportunities')
    require('./typescript/our-locations')
    require('./typescript/locations')
    require('./typescript/events')
    require('./typescript/job')
    require('./typescript/life-at-luxoft')
    require('./typescript/about-us')
    require('./typescript/carousel-speakers')
    require('./typescript/social')
    require('./typescript/global-search')
    require('./typescript/pages-form')
    require('./typescript/save-jobs')
    require('./typescript/banner-text-snippet')
    require('./typescript/grey-white-black-template')
    require('./typescript/key-technology')
    require('./typescript/how-we-hire')
    require('./typescript/e-calendar')
    require('./typescript/primary-project-roles')
    require('./js/event-form')
    require('./js/job-form')
  },
  false
)
