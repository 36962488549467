import Splide, {PaginationData, PaginationItem} from "@splidejs/splide"
import {enableThreeDotsBtns, enableMobileShareBtns, enableShareSocialBtns} from '../utils/utils'

const jobHeader: HTMLElement = document.getElementById("job-header")
const jobHeaderMobile: HTMLElement = document.getElementById("job-header-mobile")
let whiteSection: HTMLElement = document.getElementById('whiteSection')

enableThreeDotsBtns()
enableMobileShareBtns()
enableShareSocialBtns()

function handleScrollPos(): void {
    if (window.innerWidth >= 1024) {
        let whiteSectionRect: DOMRect = whiteSection.getBoundingClientRect()
        if (whiteSectionRect.top <= 0) {
            jobHeader.classList.add('show')
        } else {
            jobHeader.classList.remove('show')
        }
    }
}

function handleScrollPosi(): void {
    if (window.innerWidth < 1024) {
        let whiteSectionRect: DOMRect = whiteSection.getBoundingClientRect()
        if (whiteSectionRect.top <= 600) {
            jobHeaderMobile.classList.add('show')
        } else {
            jobHeaderMobile.classList.remove('show')
        }
    }
}

if (jobHeader) {
  document.addEventListener('scroll', handleScrollPos)
  handleScrollPos()
}

if (jobHeaderMobile) {
  document.addEventListener('scroll', handleScrollPosi)
  handleScrollPosi()
}

const jobPage: HTMLElement = document.getElementById('job-page')

if (jobPage) {
  const locationCopyBtn: HTMLElement = document.getElementById('copy-btn')

  const officeLocationText: string | null = document.getElementById('office-location')?.textContent

  if (locationCopyBtn) {
    locationCopyBtn.addEventListener('click', (): void => {
      copyToClipboard(officeLocationText)
    })
  }

  const idCopyBtn: HTMLElement = document.getElementById('id-copy-btn')

  const idText: string | null = document.getElementById('id-text')?.textContent

  if (idText) {
    idCopyBtn.addEventListener("click", (): void => {
      copyToClipboard(idText)
    })
  }
}

function copyToClipboard(textToCopy): void {
    navigator.clipboard.writeText(textToCopy)
}

if (document.getElementById("splider-related-jobs")) {
  const relatedJobsCarousel: NodeListOf<Element> = document.querySelectorAll(
    "#splider-related-jobs.splide"
  )

  relatedJobsCarousel.forEach((carouselElement: any): void => {
    const carouselItems: NodeListOf<Element> = carouselElement.querySelectorAll('.splide__slide')

    const isLoopEnabled: boolean = carouselItems.length > 1
    const splide: Splide = new Splide(carouselElement, {
      type: isLoopEnabled ? 'loop' : 'slide',
      fixedWidth: "84%",
      gap: "15px",
      drag: true,
      arrows: false,
    })

    splide.on("pagination:mounted", function (data: PaginationData): void {
      data.list.classList.add('splide__pagination--custom')

      data.items.forEach(function (item: PaginationItem): void {
        item.button.classList.add('splide__pagination--custom__item')
      })
    })

    splide.mount()
  })
}

const phoneInput: HTMLElement = document.getElementById('phone')

if (phoneInput) {
  const pattern: RegExp = /^[0-9\-\+]*$/
  const keysAllowed: string[] = ['Delete', 'Backspace', 'ArrowRight', 'ArrowLeft', 'Home', 'End', 'Enter', 'Tab']

  phoneInput.addEventListener('keydown', function (e: KeyboardEvent): void {
    if (!pattern.test(e.key) && !keysAllowed.includes(e.key)) {
      e.preventDefault()
    }
  })
}
