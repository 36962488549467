import Splide, {PaginationData, PaginationItem} from "@splidejs/splide"

const hiringProcessSection: HTMLElement = document.getElementById('splider-hiring-process')

if (hiringProcessSection) {
    const spliderHiringProcess: NodeListOf<Element> = document.querySelectorAll("#splider-hiring-process.splide")

    spliderHiringProcess.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'slide',
            gap: "15px",
            drag: true,
            arrows: true,
            perMove: 1,
            perPage: 3,
            breakpoints: {
                1440: {
                    perPage: 2
                },
                1023: {
                    perPage: 1
                }
            },
            updateOnMove : true
        })

        splide.on("pagination:mounted", function (data: PaginationData): void {
            data.list.classList.add("splide__pagination--custom")

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add("splide__pagination--custom__item")
            })
        })

        splide.mount()
    })

    const arrowPrev: NodeListOf<Element> = hiringProcessSection.querySelectorAll('.splide__arrow--prev')
    const arrowNext: NodeListOf<Element> = hiringProcessSection.querySelectorAll('.splide__arrow--next')

    if (arrowPrev.length > 0) {
        arrowPrev[0].classList.add('d-none')
    }

    arrowNext[0].addEventListener('click', (): void => {
        const sliderItems: Element[] = Array.from(hiringProcessSection.querySelectorAll('.splide__slide'))

        sliderItems.forEach(function (item: HTMLElement, index: number): void {
            if (index === 0 && !item.classList.contains('is-active')) {
                arrowPrev[0].classList.remove('d-none')
            }

            if (window.innerWidth >= 1441) {
                if (index === 5 && item.classList.contains('is-next')) {
                    arrowNext[0].classList.add('d-none')
                }
            }

            if (window.innerWidth <= 1440) {
                if (index === 6 && item.classList.contains('is-next')) {
                    arrowNext[0].classList.add('d-none')
                }
            }
        })
    })

    arrowPrev[0].addEventListener('click', (): void => {
        const sliderItems: Element[] = Array.from(hiringProcessSection.querySelectorAll('.splide__slide'))

        sliderItems.forEach(function (item: HTMLElement, index: number): void {

            if (index === 0 && item.classList.contains('is-active')) {
                arrowPrev[0].classList.add('d-none')
            }

            if (window.innerWidth >= 1441) {
                if (index === 4 && item.classList.contains('is-next')) {
                    arrowNext[0].classList.remove('d-none')
                }
            }

            if (window.innerWidth <= 1440) {
                if (index === 5 && item.classList.contains('is-next')) {
                    arrowNext[0].classList.remove('d-none')
                }
            }
        })
    })
}
