<template>
    <h1 class="container page-title job-page-title">
        <a class="container job-back-btn btn-transparent back-to-events" href="/events">
            <svg class="facebook-icon">
                <use
                    xlink:href="/theme/luxoft/assets/images/icons/icons.svg#chevron-left-icon"
                ></use>
            </svg>
            <p class="body-s-regular">BACK TO EVENTS</p></a
        >
    </h1>
    {{ eventName }}
    <div class="bg-gradient"></div>

    <div id="whiteSection" class="white-background thank-you">
        <div class="container thank-you__job">
            <div class="thanks-wrapper">
                <div class="thanks-wrapper__events">
                    <img src="/theme/luxoft/assets/images/thank-you.svg" alt="Thank you"/>
                    <h4>Thank you for registering to {{ eventName }}!</h4>
                    <p>We’ve sent an email confirmation to the email address you provided. We look forward to meeting you!
                        Learn about other Luxoft events <a href="/events">here</a>.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ThankYouEvent',
    props: {
        eventName: {
            type: String,
            required: true
        }
    }
}
</script>
