import { computed, ref } from 'vue'

const perPage = ref('')
const checkedCountriesCopyForChips = ref([])
const checkedSpecializationsCopyForChips = ref([])
const selectedElements = ref([])
const allCitiesAndCountries = ref([])
const chipsToDisplay = ref([])
const limitOfChips = ref(0)
const totalOfChipsToDisplay = ref(0)
const isSearchSaved = ref(false)
const keyword = ref('')
const keywordCopyForChip = ref('')
const jobsFiltersForm = ref(null)
const hotJobs = ref(false)
const hotJobsFinished = ref(false)

const useJobsFiltersFns = (
  urlParams,
  checkedCountries,
  checkedCities,
  checkedSpecializations,
  citiesChipsMobile,
  countriesChipsMobile,
  specializationsChipsMobile,
  cityStr,
  perPageMobile
) => {

  const setUpAllCountriesAndCities = (countriesaAndCities) => {
      allCitiesAndCountries.value = [...countriesaAndCities]
  }

  const setSelectedElementsFromUrl = (countries, cities, specializations) => {

    for (const country of countries) {
      setTimeout(() => {
        if (country !== '') {

          const countryFound = allCitiesAndCountries.value.find(item => item.name === country)

          if (countryFound !== undefined) {
            checkedCountries.value = [...checkedCountries.value, country]
            countriesChipsMobile.value.push(country)
          }

          checkedCountriesCopyForChips.value = [...checkedCountriesCopyForChips.value, country]

          chipsToDisplay.value.push({
            'country': country,
            'cities': []
          })
        }
      }, 150)
    }

    for (const city of cities) {
      setTimeout(() => {
        if (city !== '') {
          checkedCities.value = [...checkedCities.value, city]

          const countryName = allCitiesAndCountries.value
            .map(country => {
              const cityFound = Object.entries(country.cities).find(([key, value]) => value === city)
              return cityFound ? country.name : null
            })
            .filter(name => name !== null)[0]

          const countryFound = selectedElements.value.find(item => item.country === countryName)

          if (countryFound) {
            chipsToDisplay.value = chipsToDisplay.value.map(item => {
              const newItem = { ...item }
              for (const [key, value] of Object.entries(newItem)) {
                if (key === 'country' && value === countryName) {
                  newItem.cities.push(city) // Modify the cities array
                }
              }
              return newItem
            })
          } else {
            chipsToDisplay.value.push({
              'country': countryName,
              'cities': [city]
            })
          }

          citiesChipsMobile.value.push(city)
        }
      }, 150)
    }

    checkedSpecializations.value = [...specializations]
    checkedSpecializationsCopyForChips.value = [...checkedSpecializations.value]
    specializationsChipsMobile.value = [...specializations]

    setTimeout(() => {
        setChipsToDisplay()
    }, 150)
  }

  const getElementsFromUrl = () => {
    keyword.value = urlParams.get('keyword') || ''
    keywordCopyForChip.value = urlParams.get('keyword') || ''
    perPage.value = urlParams.get('perPage') || ''
    hotJobs.value = !!urlParams.get('hot')
    perPageMobile.value = urlParams.get('perPage') || '15'

    let countriesFromUrl = [],
        citiesFromUrl = [],
        specializationsFromUrl = []
        const stringReplace = decodeURIComponent(window.location.search.replace(/%20/g, ' ')
          .replace(/%23/g, '#')
          .replace(/%28/g, '(')
          .replace(/%29/g, ')')
          .replace(/%5B/g, '[')
          .replace(/%5D/g, ']')
          .replace(/%2F/g, '/')
          .replace(/%2C/g, ',')
          .replace(/%2E/g, '.'))

    if (stringReplace.includes('&country[]=')) {
      countriesFromUrl = getSubstring('&country[]=', ['&city', '&specialization', '&hot', '&perPage', '&page', '&utm'], stringReplace, '&country[]=');
    }

    if (stringReplace.includes('?country[]=')) {
      countriesFromUrl = getSubstring('?country[]=', ['&city', '&specialization', '&hot', '&perPage', '&page', '&utm'], stringReplace, '?country[]=');
    }

    if (stringReplace.includes('&city[]=')) {
      citiesFromUrl = getSubstring('&city[]=', ['&specialization', '&hot', '&perPage', '&page', '&utm'], stringReplace, '&city[]=');
    }

    if (stringReplace.includes('&specialization[]=')) {
      specializationsFromUrl = getSubstring('&specialization[]=', ['&hot', '&perPage', '&page', '&utm'], stringReplace, '&specialization[]=');
    }

    setTimeout(() => {
      hotJobsFinished.value = true
    }, 600)

    setSelectedElementsFromUrl(
      countriesFromUrl,
      citiesFromUrl,
      specializationsFromUrl
    )
  }

  const getSubstring = (startStr, endStr, str, splitBy) => {
    const pos = str.indexOf(startStr) + startStr.length

    for (const param of endStr) {
        if (str.includes(param)) {
            return str.substring(pos, str.indexOf(param, pos)).split(splitBy)
        }
    }

    return str.substring(pos).split(splitBy)
  }

  const removeCheckedCitiesByCountry = (countryParam, searchAgain) => {
      const countryCities = allCitiesAndCountries.value.filter((country) => country.name === countryParam)
      .map(country => country.cities)

      const citiesNamesToRemove = countryCities.flatMap(cityToRemove => Object.values(cityToRemove))
      checkedCities.value = checkedCities.value.filter(checkedCity => !citiesNamesToRemove.includes(checkedCity))

      setTimeout(() => {
          if (searchAgain) applyFilters('desktop')
      }, 150)
  }

  const removeCheckedItem = (type, place = '', mainIndex = 0, e = null) => {
    if (type === 'country') {
        selectedElements.value.splice(mainIndex, 1)
        chipsToDisplay.value.splice(mainIndex, 1)
        checkedCountries.value = checkedCountries.value.filter(country => country !== place)
        const searchAgain = true
        removeCheckedCitiesByCountry(place, searchAgain)
    }

    if (type === 'city') {
        checkedCities.value = checkedCities.value.filter(city => city !== place)
        e.target.closest('div').classList.add('d-none')
        applyFilters('desktop')
    }

    if (type === 'specialization') {
        checkedSpecializations.value.splice(mainIndex, 1)
        e.target.closest('div').classList.add('d-none')
        applyFilters('desktop')
    }

    if (type === 'keyword') {
      keyword.value = ''
      applyFilters('desktop')
    }
  }

  const applyFilters = (version = null) => {
    let specializationsUrl = '',
      countriesUrl = '',
      citiesUrl = ''

    if (version) {
      specializationsUrl = checkedSpecializations.value.map(item => {
        return `&specialization[]=${item.replace(/#/g, '%23').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/,/g, '%2C').replace(/\//g, '%2F').replace(/\./g, '%2E')}`
      }).join('')

      countriesUrl = checkedCountries.value.map(item => {
        return `&country[]=${item.replace(/,/g, '%2C').replace(/\(/g, '%28').replace(/\)/g, '%29')}`
      }).join('')

      citiesUrl = checkedCities.value.map(item => {
        return `&city[]=${item.replace(/,/g, '%2C')}`
      }).join('')
    } else {
      specializationsUrl = specializationsChipsMobile.value.map(item => {
        return `&specialization[]=${item.replace(/#/g, '%23').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/,/g, '%2C').replace(/\//g, '%2F').replace(/\./g, '%2E')}`
      }).join('')

      countriesUrl = countriesChipsMobile.value.map(item => {
        return `&country[]=${item.replace(/,/g, '%2C').replace(/\(/g, '%28').replace(/\)/g, '%29')}`
      }).join('')

      citiesUrl = citiesChipsMobile.value.map(item => {
        return `&city[]=${item.replace(/,/g, '%2C')}`
      }).join('')
    }

    const keywordUrl = `/?keyword=${keyword.value.trim().replace(/#/g, '%23').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/,/g, '%2C').replace(/\//g, '%2F').replace(/\./g, '%2E').replace(/\+/g, '%2B')}`

    let urlWithAllParams = `${window.location.pathname}${keywordUrl}${countriesUrl}${citiesUrl}${specializationsUrl}`

    if (hotJobs.value) {
      urlWithAllParams += `&hot=yes`
    }

    if (perPage.value) {
      urlWithAllParams += `&perPage=${perPage.value}`
    }

    window.location.href = urlWithAllParams
  }


  const setChipsToDisplay = () => {
      setTimeout(() => {
          const chipsBtns = document.getElementsByClassName('place-chip')
          let chipsWidth = 0
          let maxWidth = jobsFiltersForm.value.offsetWidth === 1300
                          ? 1200
                          : jobsFiltersForm.value.offsetWidth === 970
                          ? 770
                          : 700

          for (const chipBtn of chipsBtns) {
              chipsWidth += parseInt(chipBtn.offsetWidth)

              if (chipsWidth <= maxWidth) {
                  limitOfChips.value++
              } else {
                  chipBtn.classList.add('d-none')
              }
          }


          for (const chip of chipsToDisplay.value) {
              if (checkedCountriesCopyForChips.value.includes(chip.country)) {
                  totalOfChipsToDisplay.value++

                  totalOfChipsToDisplay.value += parseInt(chip.cities.length)
              } else {
                  totalOfChipsToDisplay.value += parseInt(chip.cities.length)
              }
          }

          totalOfChipsToDisplay.value += parseInt(checkedSpecializationsCopyForChips.value.length)

      }, 250)
  }

  const clearFilters = () => {
      window.location.href = '/jobs'
  }

  const saveSearch = () => {
      const saveSearch = window.location.search.replace(/#/g, '%23').replace(/\(/g, '%28').replace(/\)/g, '%29')
      localStorage.setItem('savedSearch', JSON.stringify(saveSearch))
      isSearchSaved.value = true
  }

  const removeSearch = () => {
      localStorage.removeItem('savedSearch')
      isSearchSaved.value = false
      window.location.href = '/jobs'
  }

  const updatePaginationLinks = () => {
      const paginationLinks = document.getElementsByClassName('pagination__link')

      if (paginationLinks.length > 0) {
          for (const pLink of paginationLinks) {
              if (pLink.getAttribute('href')) {
                  const pageStr = pLink?.textContent.trim()
                  let output = ``

                  if (window.location.search === '') {
                      output = `?page=${pageStr}`
                  } else {
                      output = window.location.search.includes('&page=')
                                  ? `${window.location.search.substring(0, window.location.search.lastIndexOf('&page='))}&page=${pageStr}`
                                  : window.location.search.includes('?page=')
                                  ? `${window.location.search.substring(0, window.location.search.lastIndexOf('?page='))}?page=${pageStr}`
                                  : `${window.location.search}&page=${pageStr}`
                  }
                  const isActive = pLink.classList.contains('active');
                  pLink.toggleAttribute('href', !isActive);

                  if (!isActive) {
                    const newHref = `${window.location.origin}${window.location.pathname}${output === '?page=1' ? '' : output}`;
                    pLink.setAttribute('href', newHref);
                  }
              }
          }
      }
  }

  const filteredCities = computed( () => {
    let values = []
    const anywhereItem = 'Anywhere'
    let foundIdx = -1

    if (checkedCountries.value.length > 0) {
      values = allCitiesAndCountries.value
        .filter(country => checkedCountries.value.includes(country.name))
        .flatMap(country => Object.values(country.cities))
        .sort()

      foundIdx = values.findIndex(el => el === anywhereItem)

      if (foundIdx > 0) {
        values.splice(foundIdx, 1)
        values.unshift(anywhereItem)
      }

      return values.filter(item => item.toLowerCase().startsWith(cityStr.value.toLowerCase()))
    }

    values = allCitiesAndCountries.value.flatMap(country => Object.values(country.cities)).sort()

    foundIdx = values.findIndex(el => el === anywhereItem)

    if (foundIdx !== -1) {
      values.splice(foundIdx, 1)
      values.unshift(anywhereItem)
    }

    return values.filter(item => item.toLowerCase().startsWith(cityStr.value.toLowerCase()))
  })

  const mobileFilteredCities = computed( () => {
    let values = []

    if (countriesChipsMobile.value.length > 0) {
      values = allCitiesAndCountries.value
        .filter(country => countriesChipsMobile.value.includes(country.name))
        .flatMap(country => Object.values(country.cities))
        .sort()

      return values.filter(item => item.toLowerCase().startsWith(cityStr.value.toLowerCase()))
    }

    values = allCitiesAndCountries.value.flatMap(country => Object.values(country.cities)).sort()

    return values.filter(item => item.toLowerCase().startsWith(cityStr.value.toLowerCase()))
  })

  return {
    getElementsFromUrl,
    removeCheckedCitiesByCountry,
    removeCheckedItem,
    applyFilters,
    clearFilters,
    setUpAllCountriesAndCities,
    saveSearch,
    removeSearch,
    updatePaginationLinks,
    filteredCities,
    mobileFilteredCities,
    chipsToDisplay,
    checkedCountriesCopyForChips,
    checkedSpecializationsCopyForChips,
    selectedElements,
    allCitiesAndCountries,
    limitOfChips,
    totalOfChipsToDisplay,
    isSearchSaved,
    keyword,
    keywordCopyForChip,
    jobsFiltersForm,
    hotJobs,
    hotJobsFinished
  }
}

export default useJobsFiltersFns
