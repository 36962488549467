import {computed, ref} from 'vue'

const jobsMobileFilters = ref(null)
const mobilePerPagePanel = ref(null)
const overlay = document.querySelector("#overlay-mobile")
const currentMobilPanelSection = ref('all-filters')
const allCountries= ref([])
const allCities= ref([])
const countryStr = ref('')
const specializationStr = ref('')
const keywordSearchPanel = ref(null)
const keywordSearchInput = ref(null)

const useMobileFiltersPanelFns = (
  checkedCountries,
  checkedCities,
  checkedSpecializations,
  citiesChipsMobile,
  countriesChipsMobile,
  specializationsChipsMobile,
  allSpecializations,
  perPageMobile
)=> {

  const setUpMobileCountriesAndCities = (countriesaAndCities) => {
    allCountries.value = countriesaAndCities.map(item => item.name)
    allCities.value = countriesaAndCities.flatMap(country => Object.values(country.cities)).sort()
  }

  const closePanel = () => {
    overlay.classList.remove('overlay-show')
    jobsMobileFilters.value.classList.remove('show')
    mobilePerPagePanel.value.classList.remove('show')
  }

  const applyPerPage = () => {
    let output = ``

    if (window.location.search === '') {
      output = `?perPage=${perPageMobile.value}`
    } else {
      output = window.location.search.includes('&perPage=')
        ? `${window.location.search.substring(0, window.location.search.lastIndexOf('&perPage='))}&perPage=${perPageMobile.value}`
        : window.location.search.includes('?perPage=')
          ? `${window.location.search.substring(0, window.location.search.lastIndexOf('?perPage='))}?perPage=${perPageMobile.value}`
          : `${window.location.search}&perPage=${perPageMobile.value}`
    }

    window.location.href = `${window.location.origin}${window.location.pathname}${output}`
  }

  const mobileFilteredCountries = computed( () => {
    return allCountries.value.filter(item => item.toLowerCase().startsWith(countryStr.value.toLowerCase()))
  })

  const mobileFilteredSpecializations = computed( () => {
    return allSpecializations.value.filter(item => item.toLowerCase().startsWith(specializationStr.value.toLowerCase()))
  })

  const applyChipsMobile = (type) => {
    if (type === 'countries') {
      countriesChipsMobile.value = [...new Set([...countriesChipsMobile.value, ...checkedCountries.value])]
    }

    if (type === 'cities') {
      citiesChipsMobile.value = [...new Set([...citiesChipsMobile.value, ...checkedCities.value])]
    }

    if (type === 'specializations') {
      specializationsChipsMobile.value = [...new Set([...specializationsChipsMobile.value ,...checkedSpecializations.value])]
    }

    currentMobilPanelSection.value = 'all-filters'
  }

  const removeChipMobile = (type, index) => {
    if (type === 'countries') {
      countriesChipsMobile.value.splice(index, 1)
      checkedCountries.value.splice(index, 1)
    }

    if (type === 'cities') {
      citiesChipsMobile.value.splice(index, 1)
      checkedCities.value.splice(index, 1)
    }

    if (type === 'specializations') {
      specializationsChipsMobile.value.splice(index, 1)
      checkedSpecializations.value.splice(index, 1)
    }
  }

  const openKeywordSearchMobile = () => {
    if (window.innerWidth <= 1023) {
      keywordSearchPanel.value.classList.add('show')
      keywordSearchInput.value.focus()

      setTimeout(() => {
        if (keywordSearchPanel.value.getBoundingClientRect().top !== 0) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }, 300)
    }
  }

  const applyMobileKeyword = (keywordStr) => {
    const keyWord = keywordStr.substring(0, keywordStr.lastIndexOf('-sub')).replace(/#/g, '%23').replace(/\(/g, '%28').replace(/\)/g, '%29')
    window.location.href = `${window.location.origin}${window.location.pathname}/?keyword=${keyWord}`
  }

  return {
    closePanel,
    applyPerPage,
    setUpMobileCountriesAndCities,
    applyChipsMobile,
    removeChipMobile,
    openKeywordSearchMobile,
    applyMobileKeyword,
    jobsMobileFilters,
    mobilePerPagePanel,
    currentMobilPanelSection,
    mobileFilteredCountries,
    mobileFilteredSpecializations,
    countryStr,
    specializationStr,
    keywordSearchPanel,
    keywordSearchInput
  }
}

export default useMobileFiltersPanelFns
