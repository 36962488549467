import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const mainPage: HTMLElement = document.getElementById("hero-section")
if (mainPage) {
  const target: HTMLElement = document.getElementById("whiteSection")

  gsap.set("#whiteSection", {
    position: "relative",
  })

  ScrollTrigger.create({
    trigger: "#whiteSection",
    start: "top 80%",
    end: "top top",
    scrub: true,
    onEnter: (): void => {
      let distanceToTop: number = Math.abs(target.getBoundingClientRect().top) * 0.8
      gsap.to("#whiteSection", {
        duration: 3,
        y: `-=${distanceToTop}px`,
        marginBottom: `-=${distanceToTop}px`,
        ease: "power4.out",
      })
    },
    onLeaveBack: (): void => {
      gsap.to("#whiteSection", {
        duration: 3,
        y: "0px",
        marginBottom: 0,
        ease: "power4.out",
      })
    },
  })

  // Add Schema Organization
  const script: HTMLScriptElement = document.createElement("script")
  script.type = "application/ld+json"

  script.textContent = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "LUXOFT",
    alternateName: "LUXOFT",
    url: "http://127.0.0.1:8000/",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+48 (077) 777 77 77",
        contactType: "customer service",
        areaServed: "PL",
        availableLanguage: ["en", "Polish"],
      },
      {
        "@type": "ContactPoint",
        telephone: "+1 (055) 555 55 55",
        contactType: "customer service",
        areaServed: "US",
        availableLanguage: ["en"],
      },
      {
        "@type": "ContactPoint",
        telephone: "+44 (033) 333 33 33",
        contactType: "customer service",
        areaServed: "GB",
        availableLanguage: ["en"],
      },
    ],
  })

  // Get the head element of the document
  const head: HTMLHeadElement = document.head || document.getElementsByTagName("head")[0]

  // Append the script element to the head
  head.appendChild(script)
}
