const mOverlay: Element = document.querySelector('#overlay-mobile')
const overlayLang: Element = document.querySelector('#overlay-language')
const mobileMenu: Element = document.querySelector('#mobile-menu')
const openBtn: Element = document.querySelector('#mobile-menu-button')
const closeBtn: Element = document.querySelector('#mobile-menu-close')
const languageMenu: Element = document.querySelector('#language-menu')
const menuFirstLevel: HTMLElement = document.getElementById('menu-first-level')
const menuSecondLevel: HTMLElement = document.getElementById('menu-second-level')
const menuSecondLevelLocations: HTMLElement = document.getElementById("menu-second-level-locations")
const openSecondLevel: HTMLElement = document.getElementById('open-second-level')
const openSecondLevelLocations: HTMLElement = document.getElementById("open-second-level-locations")
const mobileMenuHeader: HTMLElement = document.getElementById('mobile-menu-header')
const headerTitle: HTMLElement = document.getElementById('header-title')
const mobileMenuBackBtn: HTMLElement = document.getElementById('mobile-menu-back')
const closeShareIcon: HTMLElement = document.getElementById('close-share-icon')
const closeShareBtn: HTMLElement = document.getElementById('close-share-btn')
const mobileSharePanel: HTMLElement = document.getElementById('mobile-share-panel')
const mobileSuccessPanel: HTMLElement = document.getElementById('mobile-successfully-panel')
const closeSuccessfullyBtn: HTMLElement = document.getElementById('close-successfully-btn')
const mobileJobsBtn: HTMLElement = document.getElementById('mobile-jobs-button')
const jobsPerPageMobileBtn: HTMLElement = document.getElementById('jobs-per-page-mobile-btn')
const mobileFiltersBtn: HTMLElement = document.getElementById('mobile-filters-btn')
const mobileFiltersPanel: HTMLElement = document.getElementById('mobile-filters-panel')
const mobilePerPagePanel: HTMLElement = document.getElementById('mobile-perpage-panel')
let firstLevelItemText: HTMLParagraphElement

if (openSecondLevel) {
    firstLevelItemText = openSecondLevel.querySelector('p')
}

if (mobileFiltersBtn) {
  mobileFiltersBtn.addEventListener('click', (e: MouseEvent): void => {
    e.preventDefault()
    mOverlay.classList.add('overlay-show')
    mobileFiltersPanel.classList.add('show')
  })
}

if (jobsPerPageMobileBtn) {
  jobsPerPageMobileBtn.addEventListener('click', (e: MouseEvent): void => {
    e.preventDefault()
    mOverlay.classList.add('overlay-show')
    mobilePerPagePanel.classList.add('show')
  })
}

if (mobileJobsBtn) {
  mobileJobsBtn.addEventListener('click', (): void => {
    window.location.href = '/jobs'
  })
}

if (closeShareIcon) {
  closeShareIcon.addEventListener('click', (): void => {
    mobileSharePanel.classList.remove('show')
    mOverlay.classList.remove('overlay-show')
    localStorage.removeItem('shareJobMobile')
  })
}

if (closeShareBtn) {
  closeShareBtn.addEventListener('click', (): void => {
    mobileSharePanel.classList.remove('show')
    mOverlay.classList.remove('overlay-show')
    localStorage.removeItem('shareJobMobile')
  })
}

if (closeSuccessfullyBtn) {
  closeSuccessfullyBtn.addEventListener('click', (): void => {
    mOverlay.classList.remove('overlay-show')
    mobileSuccessPanel.classList.remove('show')
  })
}

if (window.location.pathname !== '/iykyk') {
    mobileMenuBackBtn.addEventListener('click', (): void => {
        headerTitle.innerText = 'MENU'
        menuFirstLevel.classList.toggle('hidden')
        menuSecondLevel.classList.toggle('shown')
        menuSecondLevelLocations.classList.remove('shown')
        menuSecondLevel.classList.remove('shown')
        mobileMenuHeader.classList.remove("second-level-opened")
        mobileMenuHeader.classList.remove("second-level-locations-opened")
        mobileMenu.classList.remove("second-level-opened")
        mobileMenu.classList.remove("second-level-locations-opened")
    })

    openSecondLevel.addEventListener('click', (e: MouseEvent): void => {
        e.preventDefault()
        headerTitle.innerText = firstLevelItemText.innerText
        menuFirstLevel.classList.toggle('hidden')
        menuSecondLevelLocations.classList.toggle("hidden")
        menuSecondLevel.classList.toggle('shown')
        mobileMenuHeader.classList.toggle('second-level-opened')
        mobileMenu.classList.toggle('second-level-opened')
    })

    openSecondLevelLocations.addEventListener('click', (e: MouseEvent): void => {
        e.preventDefault()
        headerTitle.innerText = 'LOCATIONS'
        menuFirstLevel.classList.toggle("hidden")
        menuSecondLevel.classList.toggle("hidden")
        menuSecondLevelLocations.classList.toggle("shown")
        mobileMenuHeader.classList.toggle("second-level-locations-opened")
        mobileMenu.classList.toggle("second-level-locations-opened")
    })

    openBtn.addEventListener('click', (): void => {
        mobileMenu.classList.toggle('menu-open')
        mOverlay.classList.toggle('overlay-show')
    })

    closeBtn.addEventListener('click', (): void => {
        mobileMenu.classList.remove('menu-open')
        mOverlay.classList.remove('overlay-show')
    })

    mOverlay.addEventListener('click', (): void => {
        mobileMenu.classList.remove('menu-open')
        mOverlay.classList.remove('overlay-show')

        if (mobileFiltersPanel) {
            mobileFiltersPanel.classList.remove('show')
        }

        if (mobileSharePanel) {
            mobileSharePanel.classList.remove('show')
        }

        if (mobileSuccessPanel) {
            mobileSuccessPanel.classList.remove('show')
        }

        if (mobilePerPagePanel) {
            mobilePerPagePanel.classList.remove('show')
        }

        localStorage.removeItem('shareJobMobile')
    })

    overlayLang.addEventListener('click', (): void => {
        languageMenu.classList.remove('menu-open')
        overlayLang.classList.remove('overlay-show')
    })
}
