const pageFormEndDate: HTMLElement = document.getElementById('page-form-end-date')

if (pageFormEndDate) {
    const endDate: string = pageFormEndDate.textContent.trim()
    const currentDate: string = formatDate(new Date())

    if (endDate !== '' && endDate < currentDate) {
      document.getElementById('page-form-container').remove()
    }
}

function formatDate(date: Date): string {
  const year: number = date.getFullYear()
  const month: string = String(date.getMonth() + 1).padStart(2, '0')
  const day: string = String(date.getDate()).padStart(2, '0')
  const hours: string = String(date.getHours()).padStart(2, '0')
  const minutes: string = String(date.getMinutes()).padStart(2, '0')

  return `${month}-${day}-${year} ${hours}:${minutes}`
}
