import {
  validate,
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_ALPHABETIC,
  VALIDATOR_EMAIL, VALIDATOR_PHONE, VALIDATOR_ALPHANUMERIC,
  VALIDATOR_ALPHABETIC_ALPHANUMERIC
} from '../utils/validators'

const jobForm = document.getElementById('job-form')

if (jobForm) {
  const urlParams = new URLSearchParams(window.location.search)
  document.getElementById("utm-campaign").value = urlParams.get("utm_campaign") || ""
  document.getElementById("utm-medium").value = urlParams.get("utm_medium") || ""
  document.getElementById("utm-source").value = urlParams.get("utm_source") || ""
  document.getElementById("utm-traffic-source").value = urlParams.get("utm_traffic_source") || ""
  document.getElementById("utm-content").value = urlParams.get("utm_content") || ""
  document.getElementById("utm-term").value = urlParams.get("utm_term") || ""

  const linkedinInput = document.getElementById("linkedin-link")
  const linkedinStatus = document.getElementById("linkedin-status")
  const submitBtn = document.getElementById('submit-button')

  if (
    linkedinInput &&
    linkedinStatus &&
    submitBtn
  ) {
    linkedinInput.addEventListener("input", function () {
      const urlInput = this.value
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
      if (urlPattern.test(urlInput)) {
        linkedinStatus.textContent = "Valid!"
        linkedinStatus.classList.remove("text-danger")
        linkedinStatus.classList.add("text-success")
        submitBtn.removeAttribute("disabled")
      } else {
        linkedinStatus.textContent = "Please enter a valid URL!"
        linkedinStatus.classList.remove("text-success")
        linkedinStatus.classList.add("text-danger")
        submitBtn.setAttribute("disabled", "true")
      }
    })
  }

  const fileInputIds = Array.from(document.querySelectorAll('input[type="file"]')).map((input) => input.getAttribute('id'))

  if (fileInputIds.length) {
    fileInputIds.forEach((id) => {
      const fileInput = document.getElementById(id)
      const errorMessage = document.getElementById(`form-error-${fileInput.dataset["index"]}`)
      fileInput.addEventListener("change", () => {
        const allowedExtensions = ["doc", "txt", "pdf", "docx"]
        const maxFileSize = 1 * 1024 * 1024 // 1MB in bytes

        if (fileInput.files.length > 0) {
          const fileName = fileInput.files[0].name
          const fileSize = fileInput.files[0].size

          const fileExtension = fileName.split(".").pop().toLowerCase()
          if (!allowedExtensions.includes(fileExtension)) {
            fileInput.value = ""
            errorMessage.querySelector("p").textContent = "Only .doc, .txt, .pdf, and .docx files are allowed."
            errorMessage.classList.remove("hidden")
            errorMessage.classList.remove("success")
          } else if (fileSize > maxFileSize) {
            errorMessage.querySelector("p").textContent = "File size exceeds the allowed limit (1MB)."
            errorMessage.classList.remove("hidden")
            errorMessage.classList.remove("success")
            fileInput.value = ""
          } else {
            errorMessage.classList.add("success")
            errorMessage.classList.remove("hidden")
            errorMessage.querySelector("p").textContent = `File "${fileName}" is attached`
          }
        }
      })
    })
  }

  let isValid = true
  const textFields = jobForm.querySelectorAll('input[type="text"]')
  const numberFields = jobForm.querySelectorAll('input[type="number"]')
  const emailField = jobForm.querySelectorAll('input[type="email"]')
  const phoneField = jobForm.querySelectorAll('input[type="tel"]')
  const selectFields = jobForm.querySelectorAll("select")
  const countrySelect = document.getElementById('country_code')
  let selectedCountryCode = ''

  const formValidation = {
    fieldRules: {
      first_name: {
        validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC(), VALIDATOR_MINLENGTH(3), VALIDATOR_MAXLENGTH(30)],
        is_required: true
      },
      last_name: {
        validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC(), VALIDATOR_MINLENGTH(3), VALIDATOR_MAXLENGTH(30)],
        is_required: true
      },
      email: {
        validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL(), VALIDATOR_MAXLENGTH(40)],
        is_required: true
      },
      city: {
        validators: [VALIDATOR_ALPHABETIC(), VALIDATOR_MINLENGTH(3), VALIDATOR_MAXLENGTH(30)],
        is_required: false
      },
      phone: {
        validators: [VALIDATOR_PHONE(), VALIDATOR_MINLENGTH(10), VALIDATOR_MAXLENGTH(20)],
        is_required: false
      },
      specialization: {
        validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC_ALPHANUMERIC(), VALIDATOR_MINLENGTH(3), VALIDATOR_MAXLENGTH(30)],
        is_required: true
      },
      salary_expectations_amount: {
        validators: [VALIDATOR_ALPHANUMERIC(), VALIDATOR_MINLENGTH(3), VALIDATOR_MAXLENGTH(15)],
        is_required: false
      },
      years_of_experience: {
        validators: [],
        is_required: false
      },
      country_code: {
        validators: [],
        is_required: true
      },
      salary_expectations_currency: {
        validators: [],
        is_required: false
      },
    },
    showErrorMessage(field, message = '') {
      const index = field.getAttribute("data-index")
      const errorContainer = document.getElementById("form-error-" + index)
      if (errorContainer) {
        if (message) {
          errorContainer.querySelector("p").textContent = message
        }

        errorContainer.classList.remove("hidden")
      }
    },
    hideErrorMessage(field) {
      const index = field.getAttribute("data-index")
      const errorContainer = document.getElementById("form-error-" + index)
      if (errorContainer) {
        errorContainer.classList.add("hidden")
      }
    },
    validateInputs(allInputs, source = null) {
      isValid = true
      let keyPressed = false
      const $that = this
      let validationResults = {}

      for (const inputs of allInputs) {
        inputs.forEach(function (field) {
          if (source === 'submit') {

            const { inputIsValid, message} = validate(field.value.trim(), $that.fieldRules[field.id].validators)
            validationResults = { ...validationResults, ...{ [field.id]: inputIsValid }}

            if (!inputIsValid) {
              $that.showErrorMessage(field, message)
            } else {
              $that.hideErrorMessage(field)
            }

            if (!$that.fieldRules[field.id].is_required && field.value.trim() === '') {
              $that.hideErrorMessage(field)
              validationResults[field.id] = true
            }

            for (const [key, value] of Object.entries(validationResults)) {
              if (!value) {
                isValid = false
              }
            }

          } else {
            field.addEventListener('keyup', function (e) {
              if (!keyPressed && e.key !== 'Tab') {
                keyPressed = true

                setTimeout(() => {
                  const { inputIsValid, message} = validate(this.value.trim(), $that.fieldRules[field.id].validators)

                  if (!inputIsValid) {
                    $that.showErrorMessage(field, message)
                  } else {
                    $that.hideErrorMessage(field)
                  }

                  if (!$that.fieldRules[field.id].is_required && this.value.trim() === '') {
                    $that.hideErrorMessage(field)
                  }

                  keyPressed = false
                }, 1500)
              }
            })

            field.addEventListener('focusout', function (e) {
              if ($that.fieldRules[field.id].is_required && this.value.trim() === '') {
                $that.showErrorMessage(field, 'This field is required')
              }
            })
          }
        })
      }
    },
    validateFile() {
      const $that = this
      const fileInputIds = Array.from(document.querySelectorAll('input[type="file"]')).map((input) => input.getAttribute('id'))

      if (fileInputIds.length) {
        fileInputIds.forEach((id) => {
          const fileInput = document.getElementById(id)
          if (fileInput.dataset['required'] && fileInput.files.length === 0) {
            isValid = false
            $that.showErrorMessage(fileInput)
          }
        })
      }
    },
    validateCountry() {
      if (countrySelect && selectedCountryCode === '') {
        isValid = false
        formValidation.showErrorMessage(countrySelect)
      }
    },
    init() {
      this.validateInputs([textFields, numberFields, emailField, phoneField, selectFields])
    }
  }

  formValidation.init()

  if (submitBtn) {
    submitBtn.addEventListener('click', function (e) {
      formValidation.validateInputs([textFields, numberFields, emailField, phoneField, selectFields], 'submit')
      formValidation.validateFile()
      formValidation.validateCountry()

      if (!isValid) {
        e.preventDefault() // Prevent form submission if validation fails
      }
    })
  }

  jobForm.addEventListener('submit', function (e) {
    e.preventDefault()

    const formError = document.getElementById('form-error')
    const formData = new FormData(this)
    const jobSlug = formData.get('job_slug')
    submitBtn.setAttribute('disabled', 'true')

    fetch('/ajax/job-save-form', {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.status !== "success") {
        formError.textContent = `Error: ${data.message}`
        submitBtn.removeAttribute('disabled')
        return
      }

      window.location.href = "/jobs/" + jobSlug + "/application-confirmation"
    })
    .catch(error => {
      submitBtn.removeAttribute('disabled')
      let message = error.message
      if (error.response && error.response.status === 422) {
        const response = error.response.json()
        if (response && response.message) {
          message = response.message
        }
      }

      formError.textContent = `Error: ${message}`
    })
  })


  const dropdownModal = new bootstrap.Modal("#dropdown-modal", {})

  let x, i, j, l, ll, selElmnt, a, b, c
  /* Look for any elements with the class "custom-select": */
  x = document.getElementsByClassName("custom-select")
  l = x.length
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0]
    ll = selElmnt.length
    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV")
    a.setAttribute("class", "select-selected")
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML
    x[i].appendChild(a)

    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV")
    b.setAttribute("class", "select-items select-hide")
    for (j = 0; j < ll; j++) {
      /* For each option in the original select element,
    create a new DIV that will act as an option item: */
      c = document.createElement("DIV")
      c.innerHTML = selElmnt.options[j].innerHTML
      c.addEventListener("click", function (e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        let y, i, k, s, h, sl, yl
        s = this.parentNode.parentNode.getElementsByTagName("select")[0]
        sl = s.length
        h = this.parentNode.previousSibling
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i
            h.innerHTML = this.innerHTML
            y = this.parentNode.getElementsByClassName("same-as-selected")
            yl = y.length
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class")
            }
            this.setAttribute("class", "same-as-selected")
            break
          }
        }
        h.click()
      })
      b.appendChild(c)
    }
    x[i].appendChild(b)
    a.addEventListener("click", function (e) {
      if (window.innerWidth >= 1024) {
        /* When the select box is clicked, close any other select boxes,
      and open/close the current select box: */
        e.stopPropagation()
        closeAllSelect(this)
        this.nextSibling.classList.toggle("select-hide")
        this.classList.toggle("select-arrow-active")
      } else {
        const clickedSelect = this.parentElement.querySelector("select")
        ll = clickedSelect.length

        const modalTitle = document.getElementById("dropdown-modal-label")
        const labelText =
          clickedSelect.parentNode.parentNode.getElementsByTagName("label")[0]
            ?.innerHTML || "Currency" // if the dropwdown is placed elsewhere than the other dropdowns in dom. Atm, only the currency one behaves like this
        modalTitle.textContent = labelText.split(" ").slice(0, 3).join(" ")

        /* For each element, create a new DIV that will contain the option list: */
        b = document.createElement("DIV")
        b.setAttribute("class", "select-items select-hide")
        for (j = 0; j < ll; j++) {
          /* For each option in the original select element,
    create a new DIV that will act as an option item: */
          c = document.createElement("DIV")
          c.innerHTML = clickedSelect.options[j].innerHTML
          c.addEventListener("click", function (e) {
            /* When an item is clicked, update the original select box,
        and the selected item: */
        
            let y, i, k, s, h, sl, yl
            s = clickedSelect
            sl = s.length
            h = clickedSelect.parentNode.getElementsByTagName("div")[0]
            for (i = 0; i < sl; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i
                h.innerHTML = this.innerHTML
                const contryField = document.getElementById('country_code')
                if (this.innerHTML === 'Select your country') {
                  formValidation.showErrorMessage(contryField)
                  selectedCountryCode = ''
                } else {
                  selectedCountryCode = this.innerHTML
                  formValidation.hideErrorMessage(contryField)
                }
                y = this.parentNode.getElementsByClassName("same-as-selected")
                yl = y.length
                for (k = 0; k < yl; k++) {
                  y[k].removeAttribute("class")
                }
                this.setAttribute("class", "same-as-selected")
                break
              }
            }
          })
          b.appendChild(c)
        }
        const dynamicContent = document.getElementById("dynamic_content")
        dynamicContent.textContent = ""
        dynamicContent.appendChild(b)
        dropdownModal.show()
      }
    })
  }

  let lastSelectClicked = null
  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document, except the current select box: */
    let i, arrNo = []
    const selectItems = document.getElementsByClassName("select-items")
    const selectSelected = document.getElementsByClassName("select-selected")

    for (i = 0; i < selectSelected.length; i++) {
      if (elmnt == selectSelected[i]) {
        arrNo.push(i)
      } else {
        selectSelected[i].classList.remove("select-arrow-active")
      }
    }
    for (i = 0; i < selectItems.length; i++) {
      if (arrNo.indexOf(i)) {
        selectItems[i].classList.add("select-hide")
      }
    }

    if (elmnt?.classList?.contains('select-selected')) {
      lastSelectClicked = elmnt
    }

    if (elmnt.target !== undefined && lastSelectClicked) {
      const closestSelect = lastSelectClicked.previousElementSibling

      if (closestSelect && closestSelect?.id === 'country_code') {
        if (closestSelect.value === '') {
          formValidation.showErrorMessage(closestSelect)
          selectedCountryCode = ''
        } else {
          selectedCountryCode = closestSelect.value
          formValidation.hideErrorMessage(closestSelect)
        }
      }
    }
  }

  /* If the user clicks anywhere outside the select box, then close all select boxes: */
  document.addEventListener('click', closeAllSelect)

  const clearResumeBtn = document.getElementById('clear-resume-btn')

  if (clearResumeBtn) {
    clearResumeBtn.addEventListener('click', (e) => {
      e.preventDefault()
      document.getElementById('CV_FILE').value = ''
      document.getElementById('form-error-8').classList.add('hidden')
      document.getElementById('form-error-8').classList.remove('success')
      document.getElementById('form-error-8').querySelector('p').textContent = 'This field is required'
    })
  }
}
