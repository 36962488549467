const header: HTMLElement = document.getElementById("header")
const headerBanner: HTMLElement = document.getElementById('blog-post-banner')
const whiteSection: HTMLElement = document.getElementById("whiteSection")
const blackSection: HTMLElement = document.getElementById("blackSection")
const blackSection2: HTMLElement = document.getElementById("blackSection2")
const greySection: HTMLElement = document.getElementById("greySection")
const blogContent: HTMLElement = document.getElementById('blog-content')
const blogRightColumn: Element = document.querySelector('.blog-post__right-column')
const relatedContentRightColumn: HTMLElement = document.querySelector('.blog-post__right-column .related-content')
const relatedContentBottom: Element = document.querySelector('.blog-post__related-content')
const authorsContainer: HTMLElement = document.querySelector('.blog-post__right-column--authors')
const blogContentSeparator: HTMLElement = document.getElementById('blog-content-separator')
const blogRightColumnSeparator: HTMLElement = document.getElementById('blog-right-column-separator')
const hiringProcessCarousel: HTMLElement = document.getElementById('hiring-process-carousel')
const gridItemParallax1: HTMLElement = document.getElementById('grid-item-parallax-1')
const gridItemParallax2: HTMLElement = document.getElementById('grid-item-parallax-2')
const gridItemParallax3: HTMLElement = document.getElementById('grid-item-parallax-3')
const gridItemParallaxEvents: HTMLElement = document.getElementById('eventsCard')
let headerHeight: number = 0
let headerHeightVersion: number = 1
let scrollPx: number = 0
let pxToReduce: number = 420
let pxToReduce2: number = 390
let scrollFromTop: number = 148
let maxScroll: number = 0

if (headerBanner && relatedContentRightColumn) {
  headerHeight = headerBanner.offsetHeight
  if (headerHeight === 223) {
    blogContentSeparator.classList.add('small')
    blogRightColumnSeparator.classList.add('small')
    pxToReduce = 390
    pxToReduce2 = 410
    scrollFromTop = 148
    scrollPx = (blogRightColumn.classList.contains('no-authors')) ? 90 : 70
  } else if (headerHeight === 270) {
    blogContentSeparator.classList.add('medium')
    blogRightColumnSeparator.classList.add('medium')
    headerHeightVersion = 2
    scrollPx = (blogRightColumn.classList.contains('no-authors')) ? 100 : 80
    scrollFromTop = 148
  } else if (headerHeight === 317) {
    blogContentSeparator.classList.add('large')
    blogRightColumnSeparator.classList.add('large')
    headerHeightVersion = 3
    scrollPx = (blogRightColumn.classList.contains('no-authors')) ? 110 : 90
    scrollFromTop = 148
  }

  maxScroll = relatedContentRightColumn.offsetHeight + 180 + scrollPx
}

const SCREEN_SIZES = Object.freeze({
  DESKTOP: Symbol(),
  TABLET: Symbol()
})

const BREAKPOINTS = {
  MOBILE: 1023,
  TABLET: 1439,
}

function getCurrentDevice(): symbol {
  if (window.innerWidth > BREAKPOINTS.TABLET) return SCREEN_SIZES.DESKTOP
  if (
    window.innerWidth <= BREAKPOINTS.TABLET &&
    window.innerWidth > BREAKPOINTS.MOBILE
  )
    return SCREEN_SIZES.TABLET
}

let lastScrollTop: number = 0

function setParalaxValus (version, value): void {
  if (version === SCREEN_SIZES.DESKTOP) {
    gridItemParallax1.style.transform = `translate(0px, ${value}px)`
    gridItemParallax2.style.transform = `translate(0px, ${value}px)`
    gridItemParallaxEvents.style.transform = `translate(0px, ${value}px)`
  }

  if (version === SCREEN_SIZES.TABLET) {
    gridItemParallax1.style.transform = `translate(0px, ${value}px)`
    gridItemParallaxEvents.style.transform = `translate(0px, ${value}px)`
  }
}

let scrollFTop: number = window.pageYOffset || document.documentElement.scrollTop

if (
  gridItemParallax1 &&
  gridItemParallax2 &&
  gridItemParallax3 &&
  gridItemParallaxEvents
) {
  const currentVersion: symbol = getCurrentDevice()

  if (currentVersion === SCREEN_SIZES.DESKTOP) {
    if (scrollFTop > 390) {
      setParalaxValus(SCREEN_SIZES.DESKTOP, 0)
    } else if (scrollFTop > 0 && scrollFTop < 390) {
      const value: number = -40 + (0.10 * scrollFTop)
      setParalaxValus(SCREEN_SIZES.DESKTOP, value)
    }
  } else if(currentVersion === SCREEN_SIZES.TABLET) {
    if (scrollFTop > 390) {
      setParalaxValus(SCREEN_SIZES.TABLET, -40)
    } else if (scrollFTop > 0 && scrollFTop < 390) {
      const value: number = (0.10 * scrollFromTop) * -1
      setParalaxValus(SCREEN_SIZES.TABLET, value)
    }
  }
}

function handleScrollPo(): void {
  let  scrollFromTop: number = window.pageYOffset || document.documentElement.scrollTop

  if (
    gridItemParallax1 &&
    gridItemParallax2 &&
    gridItemParallax3 &&
    gridItemParallaxEvents
  ) {
    const currentVersion: symbol = getCurrentDevice()

    if (currentVersion === SCREEN_SIZES.DESKTOP) {
      if (scrollFromTop < 390 && scrollFromTop > lastScrollTop) {
        const value: number = -40 + (0.10 * scrollFromTop)
        setParalaxValus(SCREEN_SIZES.DESKTOP, value)
      } else if (scrollFromTop < lastScrollTop && scrollFromTop < 390) {
        const value: number = (-40 - (0.10 * scrollFromTop) * -1)
        setParalaxValus(SCREEN_SIZES.DESKTOP, value)
      }
    } else if(currentVersion === SCREEN_SIZES.TABLET) {
      if (scrollFromTop < 390 && scrollFromTop > lastScrollTop) {
        const value: number = (0.10 * scrollFromTop) * -1
        setParalaxValus(SCREEN_SIZES.TABLET, value)
      } else if (scrollFromTop < lastScrollTop && scrollFromTop < 390) {
        const value: number = -40 + (0.10 * scrollFromTop)
        setParalaxValus(SCREEN_SIZES.TABLET, value)
      }
    }
  }

  scrollFromTop = scrollFromTop <= 0 ? 0 : scrollFromTop

  if (window.innerWidth >= 1024) {
    /*Apply classes for slide in bar*/
    let whiteSectionRect: DOMRect = whiteSection?.getBoundingClientRect()
    let blackSectionRect: DOMRect = blackSection
      ? blackSection.getBoundingClientRect()
      : null
    let greySectionRect: DOMRect = greySection
      ? greySection.getBoundingClientRect()
      : null
    let blackSection2Rect: DOMRect = blackSection2
      ? blackSection2.getBoundingClientRect()
      : null

    if (relatedContentRightColumn) {
      if (
          relatedContentRightColumn.getBoundingClientRect().top <= scrollFromTop &&
          !relatedContentRightColumn.classList.contains('fixed') &&
          !relatedContentRightColumn.classList.contains('scroll')
        ) {
        relatedContentRightColumn.classList.add('fixed')
      }

      if (
          (scrollFromTop - authorsContainer.offsetHeight) < pxToReduce2 &&
          relatedContentRightColumn.classList.contains('fixed')
        ) {
        relatedContentRightColumn.classList.remove('fixed')
      }

      if (
          (relatedContentBottom.getBoundingClientRect().top - relatedContentRightColumn.offsetHeight) <= 180 &&
          !relatedContentRightColumn.classList.contains('scroll') &&
          relatedContentRightColumn.classList.contains('fixed')
        ) {

        if (!blogRightColumn.classList.contains('no-authors')) {
          relatedContentRightColumn.style.marginTop = `${scrollFromTop - authorsContainer.offsetHeight - pxToReduce}px`
        } else {
          relatedContentRightColumn.style.marginTop = `${scrollFromTop + 70}px`
        }

        relatedContentRightColumn.classList.add('scroll')
        relatedContentRightColumn.classList.remove('fixed')
      }

      if (
        relatedContentBottom.getBoundingClientRect().top >= maxScroll &&
        relatedContentRightColumn.classList.contains('scroll') &&
        !relatedContentRightColumn.classList.contains('fixed')
      ) {
        relatedContentRightColumn.style.marginTop = '0'
        relatedContentRightColumn.classList.add('fixed')
        relatedContentRightColumn.classList.remove('scroll')
      }
    }

    if (headerBanner) {
      if (scrollFromTop > (header.offsetHeight + headerBanner.offsetHeight) && !headerBanner.classList.contains('fixed')) {
        headerBanner.classList.add('fixed')
        blogContent.classList.add('scrolled')
        blogRightColumn.classList.add('scrolled')
      }

      if (headerBanner.classList.contains('fixed')) {
        if (scrollFromTop < 313 && headerHeightVersion === 1) {
          headerBanner.classList.remove('fixed')
          blogContent.classList.remove('scrolled')
          blogRightColumn.classList.remove('scrolled')
        }

        if (scrollFromTop < 360 && headerHeightVersion === 2) {
          headerBanner.classList.remove('fixed')
          blogContent.classList.remove('scrolled')
          blogRightColumn.classList.remove('scrolled')
        }

        if (scrollFromTop < 410 && headerHeightVersion === 3) {
          headerBanner.classList.remove('fixed')
          blogContent.classList.remove('scrolled')
          blogRightColumn.classList.remove('scrolled')
        }
      }
    }

    if (header) {
        if (whiteSectionRect && whiteSectionRect.top < 0) {
            //offset of navbar display
            header.classList.add("bg-white")
            header.classList.remove("bg-grey")
            header.style.position = "fixed"

            if (blackSection2) {
                if (blackSection2Rect.top <= 0) {
                    header.classList.remove("bg-white")
                }
            }
        } else {
            header.classList.remove("bg-white")
            header.style.position = "absolute"
            header.style.top = "0"
            header.classList.remove("shadow")
        }
    }

    if (blackSectionRect) {
      if (blackSectionRect.top <= 0 && whiteSectionRect.bottom < 0) {
        // Black section top reaches or passes the top of the screen
        header.classList.add("bg-black")
        header.classList.remove("bg-white")
      }
    }

    if (greySectionRect) {
      if (whiteSectionRect.top > 0 && greySectionRect.bottom >= 0) {
        // grey section top reaches or passes the top of the screen
        header.classList.add("bg-grey")
        header.classList.remove("bg-white")
      }
    }

    if (hiringProcessCarousel) {
      const arrows: NodeListOf<HTMLElement> = hiringProcessCarousel.querySelectorAll('.splide__arrows')
      if (
        hiringProcessCarousel.getBoundingClientRect().top <= 300 &&
        arrows.length > 0
      ) {
        arrows[0].style.display = 'block'
      }
    }
  }
}

const navMenuDiv: HTMLElement = document.getElementById('nav-content')
const navMenu: HTMLElement = document.getElementById('nav-toggle')

function check(e: MouseEvent): void {
  const target: EventTarget = (e && e.target) || (event && event.srcElement)

  //Nav Menu
  if (!checkParent(target, navMenuDiv) && navMenuDiv) {
    // click NOT on the menu
    if (checkParent(target, navMenu)) {
      // click on the link
      if (navMenuDiv.classList.contains('hidden')) {
        navMenuDiv.classList.remove('hidden')
      } else {
        navMenuDiv.classList.add('hidden')
      }
    } else {
      // click both outside link and outside menu, hide menu
      navMenuDiv.classList.add('hidden')
    }
  }
}

function checkParent(t: any, elm: any): boolean {
  while (t.parentNode) {
    if (t == elm) {
      return true
    }
    t = t.parentNode
  }
  return false
}

const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a')

links.forEach(function(link: HTMLAnchorElement): void {
    if (link.getAttribute('href') === window.location.pathname) {
        link.classList.add('link-disabled')
    }
})

document.addEventListener('scroll', handleScrollPo)
handleScrollPo()

document.addEventListener('click', check)
