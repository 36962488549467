const mobileSuccessfullyPanel: HTMLElement = document.getElementById('mobile-successfully-panel')
const overlay: Element = document.querySelector('#overlay-mobile')

let bookmarkButtons: NodeListOf<Element> = document.querySelectorAll('.job-bookmark')

function getBookmarkBtns(): void {
  if (bookmarkButtons.length < 1) {
    bookmarkButtons = document.querySelectorAll('.job-bookmark')
  } else {
    clearInterval(storageInterval)

    if (bookmarkButtons.length > 0) {
      bookmarkButtons.forEach((btn: Element): void => {
        addActiveClass(btn)
      })
    }

    enableBts()
  }
}

const storageInterval: NodeJS.Timeout = setInterval(getBookmarkBtns, 500)

function handleCurrentBookmarksCount(): void {
    const bookmarksLength = getBookmarks().length
    const headerSavedJobsItemImage: HTMLElement = document.querySelector('.navbar .saved-jobs .bookmark figure img')

    if (bookmarksLength >= 1) {
        headerSavedJobsItemImage.setAttribute('src', '/theme/luxoft/assets/images/icons/bookmark-purple.svg')
        const bookmarksCounter: HTMLElement = document.getElementById('bookmarks-counter');
        if (bookmarksCounter) {
            bookmarksCounter.innerHTML = `(${bookmarksLength})`
        }
        const savedJobsElements: NodeListOf<Element> = document.querySelectorAll('.saved-jobs')
        savedJobsElements[0].classList.add('booked')
    } else {
        headerSavedJobsItemImage.setAttribute('src', '/theme/luxoft/assets/images/icons/bookmark.svg')
        const bookmarksCounter: HTMLElement = document.getElementById('bookmarks-counter');
        if (bookmarksCounter) {
            bookmarksCounter.innerHTML = ``
        }
        const savedJobsElements: NodeListOf<Element> = document.querySelectorAll('.saved-jobs')
        savedJobsElements[0].classList.remove('booked')
    }
}

const makeBookmark = (title: string, subtitle: string, country: string, location: string, url: string): {title: string, subtitle: string, country: string, location: string, url: string } => ({
  title,
  subtitle,
  country,
  location,
  url,
})

const getBookmarks = () => JSON.parse(localStorage.getItem('bookmarks')) || []
const storeBookmarks = (bookmarks: object[]) => localStorage.setItem('bookmarks', JSON.stringify(bookmarks))
const addBookmark = (bookmark: object): void => {
  const bookmarks: object[] = getBookmarks()
  bookmarks.push(bookmark)
  storeBookmarks(bookmarks)
}

const removeBookmarkByIndex = (index: number): void => {
  const bookmarks = getBookmarks()
  bookmarks.splice(index, 1)
  storeBookmarks(bookmarks)
}

const findBookmarkByUrl = (url: string) => getBookmarks().findIndex( (item: object): boolean => url === item['url'] )

function enableBts(): void {
    bookmarkButtons.forEach((btn: Element): void => {
        btn.addEventListener('click', (e: Event): void => {
            e.preventDefault()

            const el: HTMLElement = btn as HTMLElement
            const job: object = JSON.parse(el.dataset.job)
            const bookmarkIndex = findBookmarkByUrl(job['url'])

            if (bookmarkIndex !== -1) {
                removeBookmarkByIndex(bookmarkIndex)
                if (btn.classList.contains('job-bookmark-list')) {
                    btn.classList.remove('bookmarked')
                } else {
                    btn.classList.remove('bookmarked')
                }
            } else {
                const newBookmark: {title: string, subtitle: string, country: string, location: string, url: string }
                    = makeBookmark(job['title'], job['subtitle'], job['country'], job['location'], job['url'])
                addBookmark(newBookmark)
                if (btn.classList.contains('job-bookmark-list')) {
                    btn.classList.add('bookmarked')
                } else {
                    btn.classList.add('bookmarked')
                }

                overlay.classList.add('overlay-show')
                mobileSuccessfullyPanel.classList.add('show')
            }

            handleCurrentBookmarksCount()
        })
    })
}

function addActiveClass(btn: Element): void {
    const el: HTMLElement = btn as HTMLElement
    const job: object = JSON.parse(el.dataset.job)
    const bookmarkIndex = findBookmarkByUrl(job['url'])

    if (bookmarkIndex !== -1) {
      btn.classList.add('bookmarked')
    } else {
      btn.classList.remove('bookmarked')
    }
}

const bookmarksCounter: HTMLElement = document.getElementById('bookmarks-counter')
if (bookmarksCounter) {
    handleCurrentBookmarksCount()
}

window.handleCurrentBookmarksCount = handleCurrentBookmarksCount
