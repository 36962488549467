if (document.getElementById('life-at-luxoft-first')) {
  document.body.style.backgroundColor = 'white'
}

const tabsContainer: HTMLElement = document.getElementById("tabs-container")

if (tabsContainer) {
  const buttons: NodeListOf<Element> = tabsContainer.querySelectorAll('.collapsible-button')
  const contents: NodeListOf<Element> = tabsContainer.querySelectorAll('.collapsible-content')

  buttons.forEach((button: Element): void => {
    button.addEventListener('click', (): void => {
      contents.forEach((content: Element): void => {
        if (content.id === button.getAttribute('data-target')) {
          content.classList.add('active')
        } else {
          content.classList.remove('active')
        }
      })

      buttons.forEach((btn: Element): void => {
        if (btn !== button) {
          btn.classList.remove('active')
        }
      })

      button.classList.add('active')
    })
  })

  // Show the first content by default
  contents[0].classList.add('active')
  buttons[0].classList.add('active')
}
