<template>
    <div>
        <div ref="jobsFiltersForm">
            <form @submit.prevent="applyFilters('desktop')" class="jobs-filters__search-container">
                <img class="jobs-filters__search-container__icon" src="/theme/luxoft/assets/images/icons/gray-magnifier.svg" width="26" height="26" alt="gray-magnifier icon">
                <input
                    class="career-search__input body-m-regular text-medium-gray"
                    placeholder="Keyword or job number"
                    v-model="keyword"
                    name="keyword"
                    @focus="openKeywordSearchMobile"
                >
                <div class="separator"></div>
                <div
                    class="dropdown"
                    :class="checkedCountries.length > 0 ? 'items-checked' : ''"
                >
                    <input
                        ref="jobsCountryInput"
                        type="text"
                        placeholder="Country"
                        class="input-search-dropdown body-m-regular"
                        v-model="countryStr"
                        @click="displayCountryDropdown = true"
                    >
                    <div v-if="checkedCountries.length > 0" class="extra-counrties-checked">
                        <span>+{{ checkedCountries.length }}</span>
                    </div>
                    <div
                        class="caret-down-wrapper"
                        ref="jobsCountryInputIconWrapper"
                        @click="displayCountryDropdown = !displayCountryDropdown"
                        :class="{ 'rotate': displayCountryDropdown === true }"
                    >
                    </div>
                    <ul
                        class="dropdown-menu"
                        :class="{ 'shown': displayCountryDropdown === true }"
                        ref="countryDropdownMenu"
                    >
                        <template v-if="mobileFilteredCountries.length > 0">
                            <li v-for="(country, key) in mobileFilteredCountries">
                                <label :for="`desk-list-${key}`">{{ country }}</label>
                                <input type="checkbox" :id="`desk-list-${key}`" :value="country" v-model="checkedCountries">
                            </li>
                        </template>
                        <template v-else>
                            <li class="no-result">
                                <span>Country not found</span>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="separator"></div>
                <div
                    class="dropdown"
                    :class="checkedCities.length > 0 ? 'items-checked' : ''"
                >
                    <input
                        ref="jobsCityInput"
                        type="text"
                        placeholder="City"
                        class="input-search-dropdown body-m-regular"
                        v-model="cityStr"
                        @click="displayCityDropdown = true"
                    >
                    <div v-if="checkedCities.length > 0" class="extra-counrties-checked">
                        <span>+{{ checkedCities.length }}</span>
                    </div>
                    <div
                        class="caret-down-wrapper"
                        ref="jobsCityInputIconWrapper"
                        @click="displayCityDropdown = !displayCityDropdown"
                        :class="{ 'rotate': displayCityDropdown === true }"
                    >
                    </div>
                    <ul
                        class="dropdown-menu"
                        :class="{ 'shown': displayCityDropdown === true }"
                        ref="cityDropdownMenu"
                    >
                        <template v-if="filteredCities.length > 0">
                            <li v-for="(city, key) in filteredCities">
                                <label :for="city">{{ city }}</label>
                                <input type="checkbox" :id="city" :value="city" v-model="checkedCities">
                            </li>
                        </template>
                        <template v-else>
                            <li class="no-result">
                                <span>City not found</span>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="separator"></div>
                <div
                    class="dropdown"
                    :class="checkedSpecializations.length > 0 ? 'items-checked' : ''"
                >
                    <input
                        ref="jobsSpecializationsInput"
                        type="text"
                        placeholder="Specialization"
                        class="input-search-dropdown body-m-regular"
                        v-model="specializationStr"
                        @click="displaySpecializationsDropdown = true"
                    >
                    <div v-if="checkedSpecializations.length > 0" class="extra-counrties-checked">
                        <span>+{{ checkedSpecializations.length }}</span>
                    </div>
                    <div
                        class="caret-down-wrapper"
                        ref="jobsSpecializationsInputIconWrapper"
                        @click="displaySpecializationsDropdown = !displaySpecializationsDropdown"
                        :class="{ 'rotate': displaySpecializationsDropdown === true }"
                    >
                    </div>
                    <ul
                        class="dropdown-menu"
                        :class="{ 'shown': displaySpecializationsDropdown === true }"
                        ref="specializationsDropdownMenu"
                    >
                        <template v-if="mobileFilteredSpecializations.length > 0">
                            <li v-for="(specialization, key) in mobileFilteredSpecializations">
                                <label :for="specialization">{{ specialization }}</label>
                                <input type="checkbox" :id="specialization" :value="specialization" v-model="checkedSpecializations">
                            </li>
                        </template>
                        <template v-else>
                            <li class="no-result">
                                <span>Specialization not found</span>
                            </li>
                        </template>
                    </ul>
                </div>
                <button type="submit" class="btn-normal">
                    <img src="/theme/luxoft/assets/images/icons/magnifier.svg" alt="magnifier icon">
                </button>
            </form>

            <div class="jobs-filters__controls">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" name="flexSwitchCheckDefault" v-model="hotJobs" >
                    <label class="form-check-label body-m-regular text-white" for="flexSwitchCheckDefault">Hot jobs</label>
                </div>
                <button v-if="isSearchSaved" @click="removeSearch" class="jobs-filters__controls__save light-purple body-m-regular">
                    <img src="/theme/luxoft/assets/images/icons/bookmark-purple-remove.svg" alt="bookmark icon">
                    Remove Search
                </button>
                <button
                    v-if="!isSearchSaved && (hotJobs || keywordCopyForChip.trim() !== '' || chipsToDisplay.length > 0 || checkedSpecializationsCopyForChips.length > 0)"
                    @click="saveSearch"
                    class="jobs-filters__controls__save text-medium-gray body-m-regular"
                >
                    <img src="/theme/luxoft/assets/images/icons/save-favourite.svg" alt="save favourite icon">
                    Save Search
                </button>
            </div>

            <div
                v-if="chipsToDisplay.length > 0 || keywordCopyForChip !== ''"
                class="jobs-filters__chips mt-3"
            >
                <div
                    v-if="keywordCopyForChip"
                    class="jobs-filters__chips jobs-filters__chips--chip place-chip"
                >
                    <p>{{ keywordCopyForChip }}</p>
                    <button @click="removeCheckedItem('keyword')">
                        <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                    </button>
                </div>

                <template v-for="(country, mainIndex) in chipsToDisplay">
                    <template v-if="checkedCountriesCopyForChips.includes(country.country)">
                        <div class="jobs-filters__chips jobs-filters__chips--chip place-chip">
                            <p>{{ country.country }}</p>
                            <button @click="(event) => removeCheckedItem('country', country.country, mainIndex, event)">
                                <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                            </button>
                        </div>

                        <div
                            v-for="(city, key) in country.cities"
                            class="jobs-filters__chips jobs-filters__chips--chip place-chip"
                        >
                            <p>{{ city }}</p>
                            <button @click="(event) => removeCheckedItem('city', city, mainIndex, event)">
                                <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                            </button>
                        </div>
                    </template>

                    <template v-if="!checkedCountriesCopyForChips.includes(country.country)">
                        <div
                            v-for="(city, key) in country.cities"
                            class="jobs-filters__chips jobs-filters__chips--chip place-chip"
                        >
                            <p>{{ city }}</p>
                            <button @click="(event) => removeCheckedItem('city', city, mainIndex, event)">
                                <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                            </button>
                        </div>
                    </template>
                </template>

                <div
                    v-for="(specialization, index) in checkedSpecializationsCopyForChips"
                    class="jobs-filters__chips jobs-filters__chips--chip place-chip"
                >
                    <p>{{ specialization }}</p>
                    <button @click="(event) => removeCheckedItem('specialization', specialization, index, event)">
                        <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                    </button>
                </div>

                <div v-if="totalOfChipsToDisplay > limitOfChips" class="jobs-filters__chips jobs-filters__chips--chip counter-chip">
                    <p>+{{ totalOfChipsToDisplay- limitOfChips}}</p>
                </div>
            </div>

            <div
                v-if="chipsToDisplay.length > 0 || keywordCopyForChip !== ''"
                class="jobs-filters__chips mt-1"
            >
                <div class="jobs-filters__chips jobs-filters__chips--chip counter-chip">
                    <button @click="clearFilters()">
                        Clear all
                    </button>
                </div>
            </div>
        </div>

        <div
            class="jobs-listing-panels"
            id="mobile-filters-panel"
            ref="jobsMobileFilters"
        >
            <div
                class="mobile-all-filters"
                :class="{ 'hidden': currentMobilPanelSection !== 'all-filters' }"
            >
                <div class="jobs-listing-panels__head main-panel">
                    <p>Filters</p>

                    <button
                        v-show="citiesChipsMobile.length > 0 || countriesChipsMobile.length > 0 || specializationsChipsMobile .length > 0"
                        @click="clearFilters()"
                        class="clear-all-btn"
                    >
                        Clear all
                    </button>
                    <button
                        v-on="{ click: isSearchSaved ? removeSearch : saveSearch }"
                        class="save-search-btn"
                        :class="{ 'saved': isSearchSaved }"
                    >
                        <img src="/theme/luxoft/assets/images/icons/favorite-purple.svg" width="24" height="24" alt="favorite icon">
                    </button>

                    <button @click="closePanel">
                        <svg class="close-icon">
                            <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#close-icon"></use>
                        </svg>
                    </button>
                </div>
                <div class="jobs-listing-panels__content">
                    <div class="input-filter-wrapper">
                        <button @click="currentMobilPanelSection = 'search-countries'">Countries <i class="arrow"></i> </button>
                    </div>
                    <div class="chips-filter-mobile">
                        <div
                            v-for="(chip, key) in countriesChipsMobile"
                            class="chips-filter-mobile--chip p-chip"
                        >
                            <p>{{ chip }}</p>
                            <button @click="removeChipMobile('countries', key)">
                                <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                            </button>
                        </div>
                    </div>
                    <div class="input-filter-wrapper">
                        <button @click="currentMobilPanelSection = 'search-cities'">Cities <i class="arrow"></i> </button>
                    </div>
                    <div class="chips-filter-mobile">
                        <div
                            v-for="(chip, key) in citiesChipsMobile"
                            class="chips-filter-mobile--chip p-chip"
                        >
                            <p>{{ chip }}</p>
                            <button @click="removeChipMobile('cities', key)">
                                <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                            </button>
                        </div>
                    </div>
                    <div class="input-filter-wrapper">
                        <button @click="currentMobilPanelSection = 'search-specializations'">Specialization <i class="arrow"></i> </button>
                    </div>
                    <div class="chips-filter-mobile">
                        <div
                            v-for="(chip, key) in specializationsChipsMobile"
                            class="chips-filter-mobile--chip p-chip"
                        >
                            <p>{{ chip }}</p>
                            <button @click="removeChipMobile('specializations', key)">
                                <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                            </button>
                        </div>
                    </div>
                </div>
                <div class="jobs-listing-panels__close">
                    <button @click="applyFilters" class="btn-normal">Apply</button>
                </div>
            </div>

            <div
                class="mobile-single-search-panel"
                :class="{ 'shown': currentMobilPanelSection === 'search-countries' }"
            >
                <div class="jobs-listing-panels__head">
                    <p>Countries</p>
                    <button @click="currentMobilPanelSection = 'all-filters'">
                        <svg class="close-icon">
                            <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#close-icon"></use>
                        </svg>
                    </button>
                </div>
                <div class="jobs-listing-panels__content">
                    <div class="filter-input-search">
                        <img src="/theme/luxoft/assets/images/icons/search-gray.svg" width="24" height="30" alt="search icon">
                        <input type="text" name="country-search" placeholder="Search countries" v-model="countryStr">
                        <button @click="countryStr = ''">
                            <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close search icon">
                        </button>
                    </div>
                </div>
                <div class="jobs-listing-panels__list checkbox-list">
                    <div class="filter-search-results mt-1">
                        <ul class="">
                            <li v-for="(country, key) in mobileFilteredCountries">
                                <label :for="`mobile-check-${key}`">{{ country }}</label>
                                <input type="checkbox" :id="`mobile-check-${key}`" :value="country" v-model="checkedCountries">
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="checkedCountries.length > 0" class="jobs-listing-panels__close">
                    <button @click="applyChipsMobile('countries')" class="btn-normal">Apply</button>
                </div>
            </div>

            <div
                class="mobile-single-search-panel"
                :class="{ 'shown': currentMobilPanelSection === 'search-cities' }"
            >
                <div class="jobs-listing-panels__head">
                    <p>Cities</p>
                    <button @click="currentMobilPanelSection = 'all-filters'">
                        <svg class="close-icon">
                            <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#close-icon"></use>
                        </svg>
                    </button>
                </div>
                <div class="jobs-listing-panels__content">
                    <div class="filter-input-search">
                        <img src="/theme/luxoft/assets/images/icons/search-gray.svg" width="24" height="30" alt="search icon">
                        <input type="text" name="country-search" placeholder="Search cities" v-model="cityStr">
                        <button @click="cityStr = ''">
                            <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close search icon">
                        </button>
                    </div>
                </div>
                <div class="jobs-listing-panels__list checkbox-list">
                    <div class="filter-search-results mt-1">
                        <ul>
                            <li v-for="(city, key) in mobileFilteredCities">
                                <label :for="city">{{ city }}</label>
                                <input type="checkbox" :id="city" :value="city" v-model="checkedCities">
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="checkedCities.length > 0" class="jobs-listing-panels__close">
                    <button @click="applyChipsMobile('cities')" class="btn-normal">Apply</button>
                </div>
            </div>

            <div
                class="mobile-single-search-panel"
                :class="{ 'shown': currentMobilPanelSection === 'search-specializations' }"
            >
                <div class="jobs-listing-panels__head">
                    <p>Specializations</p>
                    <button @click="currentMobilPanelSection = 'all-filters'">
                        <svg class="close-icon">
                            <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#close-icon"></use>
                        </svg>
                    </button>
                </div>
                <div class="jobs-listing-panels__content">
                    <div class="filter-input-search">
                        <img src="/theme/luxoft/assets/images/icons/search-gray.svg" width="24" height="30" alt="search icon">
                        <input type="text" name="country-search" placeholder="Search specializations" v-model="specializationStr">
                        <button @click="specializationStr = ''">
                            <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close search icon">
                        </button>
                    </div>
                </div>
                <div class="jobs-listing-panels__list checkbox-list">
                    <div class="filter-search-results mt-1">
                        <ul>
                            <li v-for="(specialization, key) in mobileFilteredSpecializations">
                                <label :for="specialization">{{ specialization }}</label>
                                <input type="checkbox" :id="specialization" :value="specialization" v-model="checkedSpecializations">
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="checkedSpecializations.length > 0" class="jobs-listing-panels__close">
                    <button @click="applyChipsMobile('specializations')" class="btn-normal">Apply</button>
                </div>
            </div>
        </div>

        <div class="jobs-listing-panels" id="mobile-perpage-panel" ref="mobilePerPagePanel">
            <div class="jobs-listing-panels__head">
                <p>Show сards</p>
                <button @click="closePanel">
                    <svg class="close-icon">
                        <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#close-icon"></use>
                    </svg>
                </button>
            </div>
            <div class="jobs-listing-panels__list">
                <ul>
                    <li>
                        <label for="fifteen">15</label>
                        <input type="radio" id="fifteen" value="15" name="per-page-mobile" v-model="perPageMobile">
                    </li>
                    <li>
                        <label for="thirty">30</label>
                        <input type="radio" id="thirty" value="30" name="per-page-mobile" v-model="perPageMobile">
                    </li>
                    <li>
                        <label for="forty-five">45</label>
                        <input type="radio" id="forty-five" value="45" name="per-page-mobile" v-model="perPageMobile">
                    </li>
                    <li>
                        <label for="sixty">60</label>
                        <input type="radio" id="sixty" value="60" name="per-page-mobile" v-model="perPageMobile">
                    </li>
                </ul>
            </div>
            <div class="jobs-listing-panels__close">
                <button class="btn-normal" @click="applyPerPage">Apply</button>
            </div>
        </div>

        <div class="jobs-keyword-search-panel" ref="keywordSearchPanel">
            <div class="jobs-keyword-search-panel__input-section mt-2">
                <button @click="keywordSearchPanel.classList.remove('show')" class="back-btn">
                    <img src="/theme/luxoft/assets/images/icons/gray-arrow.svg" width="24" height="24" alt="gray-arrow icon">
                </button>
                <div class="filter-input-search mt-2">
                    <img src="/theme/luxoft/assets/images/icons/search-gray.svg" width="24" height="30" alt="search icon">
                    <input type="text" name="keyword-search" placeholder="Keyword" v-model="KeywordStr" ref="keywordSearchInput">
                    <button @click="KeywordStr = ''">
                        <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close search icon">
                    </button>
                </div>
            </div>
            <div
                class="jobs-keyword-search-panel__results mt-2"
                :class="[{ 'item-selected': checkedSpeAndSum !== '' }]"
            >
                <ul v-if="filteredSpecializationsSummaries.length > 0">
                    <li v-for="(item, key) in filteredSpecializationsSummaries">
                        <div>
                            <label :for="`${item.specialization.replace(/\.|\+|#|\/|\s|_|\(|\)/g, '-')}-${key}`">{{ item.specialization }}</label>
                            <span>{{ item.summary }}</span>
                        </div>
                        <input
                            type="radio"
                            name="keyword-search"
                            :id="`${item.specialization.replace(/\.|\+|#|\/|\s|_|\(|\)/g, '-')}-${key}`"
                            :value="`${item.specialization}-sub${key}`"
                            v-model="checkedSpeAndSum"
                            :key="`${item.specialization.replace(/\.|\+|#|\/|\s|_|\(|\)/g, '-')}-${key}`"
                        >
                    </li>
                </ul>
                <div
                    v-if="filteredSpecializationsSummaries.length < 1 && KeywordStr.trim() !== ''"
                    class="nothing-found"
                >
                    <img src="/theme/luxoft/assets/images/icons/nothing-found.svg" alt="nothing-found icon">
                    <p>Nothing found, change the search parameters</p>
                </div>
            </div>
            <div v-show="checkedSpeAndSum !== ''" class="jobs-keyword-search-panel__close mt-3">
                <button class="btn-normal" @click="applyMobileKeyword(checkedSpeAndSum)">Apply</button>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import useJobsFiltersFns from '../functions/jobsFilters'
import useMobileFiltersPanelFns from '../functions/jobsMobileFilters'
import useAjaxCalls from '../functions/apiCalls'

export default {
    props: {
        countriesAndCities: {
            type: Array,
            required: true,
            default: () => [],
        },
        specializations: {
            type: Array,
            required: true,
            default: () => [],
        }
    },
    setup(props) {
        const urlParams = new URLSearchParams(window.location.search)
        const checkedSpeAndSum = ref('')
        const checkedCountries = ref([])
        const checkedCities = ref([])
        const checkedSpecializations = ref([])
        const citiesChipsMobile = ref([])
        const countriesChipsMobile = ref([])
        const specializationsChipsMobile = ref([])
        const allSpecializations = ref(props.specializations)
        const cityStr = ref('')
        const perPageMobile = ref('15')
        const displayCountryDropdown = ref(false)
        const jobsCountryInput = ref(null)
        const jobsCountryInputIconWrapper = ref(null)
        const countryDropdownMenu = ref(null)
        const displayCityDropdown = ref(false)
        const jobsCityInput = ref(null)
        const jobsCityInputIconWrapper = ref(null)
        const cityDropdownMenu = ref(null)
        const displaySpecializationsDropdown = ref(false)
        const jobsSpecializationsInput = ref(null)
        const jobsSpecializationsInputIconWrapper = ref(null)
        const specializationsDropdownMenu = ref(null)

        const {
            getSpecializationsAndSummaries,
            specializationsAndSummaries,
            filteredSpecializationsSummaries,
            KeywordStr
        } = useAjaxCalls()

        const {
            getElementsFromUrl,
            removeCheckedCitiesByCountry,
            removeCheckedItem,
            applyFilters,
            clearFilters,
            setUpAllCountriesAndCities,
            saveSearch,
            removeSearch,
            updatePaginationLinks,
            filteredCities,
            mobileFilteredCities,
            chipsToDisplay,
            checkedCountriesCopyForChips,
            checkedSpecializationsCopyForChips,
            selectedElements,
            allCitiesAndCountries,
            limitOfChips,
            totalOfChipsToDisplay,
            isSearchSaved,
            keyword,
            keywordCopyForChip,
            jobsFiltersForm,
            hotJobs,
            hotJobsFinished
            } = useJobsFiltersFns(
                urlParams,
                checkedCountries,
                checkedCities,
                checkedSpecializations,
                citiesChipsMobile,
                countriesChipsMobile,
                specializationsChipsMobile,
                cityStr,
                perPageMobile
            )

        const {
            closePanel,
            applyPerPage,
            setUpMobileCountriesAndCities,
            applyChipsMobile,
            removeChipMobile,
            openKeywordSearchMobile,
            applyMobileKeyword,
            jobsMobileFilters,
            mobilePerPagePanel,
            currentMobilPanelSection,
            mobileFilteredCountries,
            mobileFilteredSpecializations,
            countryStr,
            specializationStr,
            keywordSearchPanel,
            keywordSearchInput,
            } = useMobileFiltersPanelFns(
                checkedCountries,
                checkedCities,
                checkedSpecializations,
                citiesChipsMobile,
                countriesChipsMobile,
                specializationsChipsMobile,
                allSpecializations,
                perPageMobile
            )

        setUpMobileCountriesAndCities(props.countriesAndCities)
        setUpAllCountriesAndCities(props.countriesAndCities)

        watch(
            [checkedCountries, checkedCities, hotJobs],
            ([newCountries, newCities, newHotFilter], [oldCountries, oldCities, oldHotFilter]) => {
            /*If we add country*/
            if (newCountries.length > oldCountries.length) {
                const lastCountryAdded = newCountries[newCountries.length - 1]
                const countryFound = selectedElements.value.filter(item => item.country === lastCountryAdded)

                if (countryFound[0]) {
                    /** If the country already exists becauase we added one of its cities
                     * then we remove all the other countries that were added and keep only
                     *  those that exist in the checkedCountries
                     * */
                    selectedElements.value = selectedElements.value.filter(item => {
                        const itemFound = checkedCountries.value.filter(element => element === item.country)
                        return itemFound[0] || false
                    })

                    /**
                     * We filter the checkedCities to keep checked only those that belongs to the last country added
                     */
                    checkedCities.value = checkedCities.value.filter(item => {
                        const country = selectedElements.value.find(element => element.country === countryFound[0].country)
                        return country.cities.includes(item) || false
                    })
                } else {
                    selectedElements.value.push({
                        'country': lastCountryAdded,
                        'cities': []
                    })
                }
            }

            /*If we remove country*/
            if (oldCountries.length > newCountries.length) {
                const countryToRemove = oldCountries.filter(item => !newCountries.includes(item))

                selectedElements.value = selectedElements.value.filter(item => {
                                            for (const [key, value] of Object.entries(item)) {
                                                if (key === 'country' && value === countryToRemove[0]) {
                                                    return false // Exclude items where 'country' property matches diff[0]
                                                }
                                            }

                                            return true // Include all other items
                                        })

                const searchAgain = false
                removeCheckedCitiesByCountry(countryToRemove[0], searchAgain)
            }

            /*If we add city*/
            if (newCities.length > oldCities.length) {
                const lastCityAdded = newCities[newCities.length - 1]
                const countryName = allCitiesAndCountries.value
                    .map(country => {
                        const cityFound = Object.entries(country.cities).find(([key, value]) => value === lastCityAdded)
                        return cityFound ? country.name : null
                    })
                    .filter(name => name !== null)[0]

                const countryFound = selectedElements.value.find(item => item.country === countryName)

                if (countryFound) {
                    selectedElements.value = selectedElements.value.map(item => {
                                    const newItem = { ...item }
                                    for (const [key, value] of Object.entries(newItem)) {
                                        if (key === 'country' && value === countryName) {
                                            newItem.cities.push(lastCityAdded) // Modify the cities array
                                        }
                                    }
                                    return newItem
                                })
                } else {
                    selectedElements.value.push({
                        'country': countryName,
                        'cities': [lastCityAdded]
                    })
                }
            }

            /*If we remove city*/
            if (oldCities.length > newCities.length) {
                const cityToRemove = oldCities.filter(item => !newCities.includes(item))

                selectedElements.value = selectedElements.value.map(item => {
                                            const newItem = { ...item }
                                            newItem.cities = newItem.cities.filter(city => city !== cityToRemove[0])
                                            return newItem
                                        })
            }

            if (newHotFilter !== oldHotFilter) {
                if (urlParams.get('hot') === null || urlParams.get('hot') === '') {
                    applyFilters('desktop')
                } else if (hotJobsFinished.value) {
                    hotJobs.value = false
                    applyFilters('desktop')
                }
            }
        })

        onMounted(() => {
            if (localStorage.getItem('savedSearch') !== null) {
                isSearchSaved.value = true
            }

            if (
                window.location.search === '' &&
                isSearchSaved.value
            ) {
                const savedSearch = JSON.parse(localStorage.getItem('savedSearch'))
                if (savedSearch) {
                    window.location.href = `${savedSearch}`
                }
            } else {
                getElementsFromUrl()
            }

            updatePaginationLinks()
            getSpecializationsAndSummaries()

            document.addEventListener('click', function(e) {


                if (countryDropdownMenu.value.classList.contains('shown')) {
                    if (
                        e.target !== countryDropdownMenu.value &&
                        e.target.type !== 'checkbox' &&
                        e.target !== jobsCountryInput.value &&
                        e.target !== jobsCountryInputIconWrapper.value
                    ) {
                        countryDropdownMenu.value.classList.remove('shown')
                        displayCountryDropdown.value = false
                    }
                }

                if (cityDropdownMenu.value.classList.contains('shown')) {
                    if (
                        e.target !== cityDropdownMenu.value &&
                        e.target.type !== 'checkbox' &&
                        e.target !== jobsCityInput.value &&
                        e.target !== jobsCityInputIconWrapper.value
                    ) {
                        cityDropdownMenu.value.classList.remove('shown')
                        displayCityDropdown.value = false
                    }
                }

                if (specializationsDropdownMenu.value.classList.contains('shown')) {
                    if (
                        e.target !== specializationsDropdownMenu.value &&
                        e.target.type !== 'checkbox' &&
                        e.target !== jobsSpecializationsInput.value &&
                        e.target !== jobsSpecializationsInputIconWrapper.value
                    ) {
                        specializationsDropdownMenu.value.classList.remove('shown')
                        displaySpecializationsDropdown.value = false
                    }
                }
            })
        })

        return {
            applyFilters,
            clearFilters,
            removeCheckedItem,
            saveSearch,
            removeSearch,
            closePanel,
            applyPerPage,
            applyChipsMobile,
            removeChipMobile,
            openKeywordSearchMobile,
            applyMobileKeyword,
            chipsToDisplay,
            limitOfChips,
            keyword,
            keywordCopyForChip,
            jobsFiltersForm,
            selectedElements,
            checkedSpecializations,
            checkedSpecializationsCopyForChips,
            allSpecializations,
            checkedCities,
            allCitiesAndCountries,
            filteredCities,
            checkedCountries,
            checkedCountriesCopyForChips,
            totalOfChipsToDisplay,
            isSearchSaved,
            jobsMobileFilters,
            mobilePerPagePanel,
            perPageMobile,
            currentMobilPanelSection,
            mobileFilteredCountries,
            mobileFilteredCities,
            mobileFilteredSpecializations,
            countryStr,
            cityStr,
            specializationStr,
            citiesChipsMobile,
            countriesChipsMobile,
            specializationsChipsMobile,
            specializationsAndSummaries,
            filteredSpecializationsSummaries,
            keywordSearchPanel,
            keywordSearchInput,
            KeywordStr,
            checkedSpeAndSum,
            hotJobs,
            hotJobsFinished,
            displayCountryDropdown,
            jobsCountryInput,
            jobsCountryInputIconWrapper,
            countryDropdownMenu,
            displayCityDropdown,
            jobsCityInput,
            jobsCityInputIconWrapper,
            cityDropdownMenu,
            displaySpecializationsDropdown,
            jobsSpecializationsInput,
            jobsSpecializationsInputIconWrapper,
            specializationsDropdownMenu
        }
    }
}
</script>
