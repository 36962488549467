<template>
    <div>
        <div
            class="embedded-jobs-listing embedded-jobs-listing__overlay"
            :class="{ 'show': showCountriesPanel }"
        ></div>
        <div class="container embedded-jobs-listing" id="embedded-jobs-listing">
            <div class="row">
                <div class="col text-center">
                    <h2>Find your opportunity here</h2>
                </div>
            </div>
            <div class="jobs-filters mt-6" ref="jobsFiltersForm">
                <form @submit.prevent="applyFilters">
                    <div class="jobs-filters__search-container">
                        <input
                            class="career-search__input body-m-regular text-medium-gray"
                            placeholder="Search by keyword"
                            v-model="keyword"
                            name="keyword"
                        >
                        <div class="separator"></div>
                        <div
                            class="dropdown"
                            :class="checkedCountries.length > 0 ? 'items-checked' : ''"
                        >
                            <button
                                class="dropdown-face body-m-regular text-medium-gray"
                                type="button"
                                id="countriesDropdown"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                            >
                                {{ checkedCountries.length > 0 ? checkedCountries[0] : 'Select countries'}}
                                <div v-if="checkedCountries.length > 1" class="extra-counrties-checked">
                                    <span>+{{ checkedCountries.length - 1 }}</span>
                                </div>
                                <img src="/theme/luxoft/assets/images/icons/caret-down-black.svg" alt="caret-down-black icon">
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="countriesDropdown">
                                <li v-for="(country, key) in countries">
                                    <label :for="key">{{ country }}</label>
                                    <input type="checkbox" :id="key" :value="country" v-model="checkedCountries">
                                </li>
                            </ul>
                        </div>
                        <button type="submit" class="btn-normal">
                            <img src="/theme/luxoft/assets/images/icons/magnifier.svg" alt="magnifier icon">
                        </button>
                    </div>
                    <div class="jobs-filters__countries-container">
                        <div
                            class="dropdown"
                            :class="checkedCountries.length > 0 ? 'items-checked' : ''"
                             @click="showCountriesPanel = true"
                             id="embedded-dropdown-btn"
                        >
                            <button
                                class="dropdown-face body-m-regular text-medium-gray"
                                type="button"
                            >
                                {{ checkedCountries.length > 0 ? checkedCountries[0] : 'Select countries'}}
                                <div v-if="checkedCountries.length > 1" class="extra-counrties-checked">
                                    <span>+{{ checkedCountries.length - 1 }}</span>
                                </div>
                                <img src="/theme/luxoft/assets/images/icons/caret-down-black.svg" alt="caret-down-black icon">
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="jobs">
                <div class="loading-animation" v-if="isLoading"></div>

                <div class="jobs__list">
                    <SingleJob
                        v-for="(item, key) in filteredItems"
                        :job="item"
                        :index="key"
                        :key="key"
                    />
                </div>

                <div class="embedded-jobs-listing__pagination mt-3">
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <vue-awesome-paginate
                                    :total-items="totalItems"
                                    :items-per-page="itemsPerPage"
                                    :max-pages-shown="4"
                                    v-model="currentPage"
                                    :show-breakpoint-buttons="true"
                                    class="pagination"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div
            class="jobs-listing-panels embedded-jobs-v"
            id="mobile-filters-panel"
            :class="{ 'show': showCountriesPanel }"
        >
            <div class="jobs-listing-panels__head">
                <p>Select countries</p>
                <button @click="showCountriesPanel = false">
                    <svg class="close-icon">
                        <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#close-icon"></use>
                    </svg>
                </button>
            </div>
            <div class="jobs-listing-panels__list checkbox-list">
                <div class="filter-search-results mt-1">
                    <ul class="">
                        <li v-for="(country, key) in countries">
                            <label :for="country">{{ country }}</label>
                            <input type="checkbox" :id="country" :value="country" v-model="checkedCountries">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="jobs-listing-panels__close">
                <button @click="applyFilters" class="btn-normal" :disabled="checkedCountries.length < 1">Apply</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, reactive, computed } from 'vue'
import useAjaxCalls from '../functions/apiCalls'
import {enableThreeDotsBtns, enableShareSocialBtns, enableMobileShareBtns} from '../../utils/utils'
import SingleJob from '../components/SingleJob.vue'

export default {
    components: {
        SingleJob,
    },
    props: {
        ids: {
            type: Array,
            required: false,
            default: () => [],
        },
        countries: {
            type: Array,
            required: false,
            default: () => [],
        },
        bu: {
            type: Array,
            required: false,
            default: () => [],
        }
    },
    setup(props) {
        const itemsPerPage = 5
        const currentPage = ref(1)
        const countries = ref(props.countries)
        const showCountriesPanel = ref(false)
        const isLoading = ref(false)
        const checkedCountries = ref([])
        const keyword = ref('')
        const callSettings = reactive({
            "country": props.countries || [],
            "business_unit_path": props.bu || [],
            "keyword": keyword.value,
            "ids": props.ids || [],
        })

        const {
            ajaxFilterJobs,
            embeddedJobsOpportunities,
            totalItems
        } = useAjaxCalls()

        onMounted(async() => {
            isLoading.value = true
            await ajaxFilterJobs(callSettings)
            isLoading.value = false
            enableThreeDotsBtns()
            enableShareSocialBtns()
            enableMobileShareBtns()
        })

        const filteredItems = computed( () => {
            const values = embeddedJobsOpportunities.value.slice((currentPage.value - 1) * itemsPerPage, currentPage.value * itemsPerPage)
            if (values[0]?.title !== undefined) return values
        })

        const applyFilters = async () => {
            callSettings.country = checkedCountries.value
            isLoading.value = true
            await ajaxFilterJobs(callSettings)
            enableThreeDotsBtns()
            isLoading.value = false
            showCountriesPanel.value = false
        }

        return {
            applyFilters,
            filteredItems,
            isLoading,
            showCountriesPanel,
            countries,
            checkedCountries,
            keyword,
            totalItems,
            currentPage,
            itemsPerPage
        }
    }
}
</script>
