<template>
    <div class="container search-results">
        <div class="search-results__header-black">
            <form id="global-search" class="global-search">
                <input type="text" id="input-search" placeholder="Search" name="q" class="global-search--input">
                <button type="submit" class="btn-normal">
                    <img src="/theme/luxoft/assets/images/icons/magnifier.svg" alt="Search icon">
                </button>
            </form>
        </div>
    </div>
    <div class="bg-gradient"></div>

    <div id="whiteSection" class="white-background search-results">
        <div class="container">
            <div class="container search-results__header-white mb-5">
                <div>
                    <h4 v-if="allResultItems.length < 1 && !isLoading && !isThereAnError" class="searched-word">
                        No results were found for "{{ searchedWord }}"
                    </h4>
                    <h4 v-if="allResultItems.length > 0 && !isThereAnError" class="searched-word">
                        Results for "{{ searchedWord }}"
                    </h4>
                    <h4 v-if="isThereAnError" class="searched-word">
                        Something went wrong, please try again.
                    </h4>
                </div>
                <div v-if="allResultItems.length > 0 && !isThereAnError">
                    <p><b>{{ totalItems }}</b> results found</p>
                </div>
            </div>
            <div class="container search-results__items-wrapper">

                <div class="loading-animation" v-if="isLoading && !isThereAnError"></div>

                <SingleSearch
                    v-for="item in filteredItems"
                    :item="item"
                    :urls="absoluteUrls"
                    :excerpts="recordsExcerpts"
                    :key="item.id"
                />
            </div>
        </div>
        <div v-if="allResultItems.length > 0 && !isThereAnError" class="search-results__pagination">
            <div class="container text-center">
                <div class="row">
                    <div class="col">
                        <vue-awesome-paginate
                            :total-items="totalItems"
                            :items-per-page="itemsPerPage"
                            :max-pages-shown="5"
                            v-model="currentPage"
                            :on-click="onClickHandler"
                            :show-breakpoint-buttons="true"
                            class="career-pagination"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import SingleSearch from "../components/SingleSearch.vue";

export default {
    components: {
        SingleSearch,
    },
    setup() {
        const totalItems = ref(0)
        const currentPage = ref(1)
        const itemsPerPage = 10
        const loadedPages = ref([])
        const isLoading = ref(null)
        const isThereAnError = ref(null)
        const searchedWord = ref('')
        const jumpedPages = ref([])
        const allResultItems = ref([])
        const absoluteUrls = ref([])
        const recordsExcerpts = ref([])
        const scrollDiv = document.getElementById('app').offsetTop
        const urlParams = new URLSearchParams(window.location.search)
        const searchPage = ref(1)

        const filteredItems = computed( () => {
            const values = allResultItems.value.slice((currentPage.value - 1) * itemsPerPage, currentPage.value * itemsPerPage)
            if (values[0]?.id !== undefined) return values
        })

        const onClickHandler = (page) => {
            const paginationClicked = true
            getData(page, paginationClicked)
        }

        const getData = (page = 1, paginationClicked = false) => {
            if (!loadedPages.value.includes(page)) {
                const lastLoadedPage = loadedPages.value[loadedPages.value.length - 1]
                /**
                 * If the users click on a page bigger than the next one
                 * From page 1 to 6 for instance
                 */
                if (page > (lastLoadedPage + 1) && page !== 1) {
                    let jumpPage = lastLoadedPage + 1

                    while (jumpPage < page)  {
                        if (!loadedPages.value.includes(jumpPage)) {
                            jumpedPages.value.push(jumpPage)
                            allResultItems.value.push({}, {}, {}, {}, {}, {}, {}, {}, {}, {})
                        }
                        jumpPage++
                    }
                }

                searchedWord.value = urlParams.get('q')
                searchPage.value = page
                ajaxCallActions(page, paginationClicked)
            }
        }

        const ajaxCallActions = async (page = 1, paginationClicked = false) => {
            try  {
                isThereAnError.value = false
                isLoading.value = true
                const resultContainer = document.querySelector('.search-results__items-wrapper')

                if (resultContainer) {
                    resultContainer.style.height = `${resultContainer.offsetHeight}px`
                }

                if (paginationClicked) {
                    window.scrollTo({ top: scrollDiv, behavior: 'smooth'})
                }

                const response = await fetch('/ajax/search', {
                    method: 'POST',
                    body: JSON.stringify({
                        search: searchedWord.value,
                        page: searchPage.value
                    }),
                })

                const data = await response.json()
                totalItems.value = data.records.pagination.total_items
                loadedPages.value.push(page)

                if (jumpedPages.value.includes(page)) {
                    jumpedPages.value = jumpedPages.value.filter((item) => item !== page)
                    allResultItems.value.splice((currentPage.value - 1) * itemsPerPage, itemsPerPage, ...data.records.items)
                } else {
                    allResultItems.value = [...allResultItems.value, ...data.records.items]
                }

                absoluteUrls.value = [...absoluteUrls.value, ...data.urls]
                recordsExcerpts.value = [...recordsExcerpts.value, ...data.excerpts]

                if (resultContainer) {
                    resultContainer.style.height = 'auto'
                }

               isLoading.value = false

            } catch (error) {
                isThereAnError.value = true
            }
        }

        getData()

        return {
            absoluteUrls,
            recordsExcerpts,
            isThereAnError,
            searchedWord,
            filteredItems,
            allResultItems,
            isLoading,
            onClickHandler,
            totalItems,
            itemsPerPage,
            currentPage
        }
    }

}

</script>
