const greyTemplate: HTMLCollectionOf<Element> = document.getElementsByClassName('grey-white-black-template')
const backgroundLogo: string = '/theme/luxoft/assets/images/logo/logo-luxoft-career-purple.svg'

if (
    greyTemplate.length > 0 &&
    window.innerWidth < 1024
) {
    const header: HTMLElement = document.getElementById('up-menu-logo')
    header.style.backgroundImage = `url(${backgroundLogo})`
}
