import {computed, ref} from "vue";
import axios from "axios";

const specializationsAndSummaries = ref([])
const embeddedJobsOpportunities = ref([])
const KeywordStr = ref('')
const totalItems = ref(0)

const useAjaxCalls = () => {
  const getSpecializationsAndSummaries = async () =>  {
    try {
      const response = await fetch('/ajax/get-specializations-and-summaries', {
        method: 'POST',
      })

      if (!response) {
        throw new Error('Could not load the elements')
      }

      const data = await response.json()
      specializationsAndSummaries.value = [...data.data]
    } catch (error) {
      console.log(error)
    }
  }

  const ajaxFilterJobs = async (callSettings) => {
    try {
      const response = await axios.get('/ajax/filter-jobs', {
        params: callSettings
      })

      if (!response) {
        throw new Error('Could not load the elements')
      }

      embeddedJobsOpportunities.value = [...response.data]
      totalItems.value = embeddedJobsOpportunities.value.length
    } catch (e) {
      console.log(e)
    }
  }

  const filteredSpecializationsSummaries = computed( () => {
    if (KeywordStr.value.trim() !== '') {
      let values = []
      values = specializationsAndSummaries.value.filter(item => item.specialization.toLowerCase().includes(KeywordStr.value.toLowerCase()))
      return values.slice(0, 15)
    }

    return []
  })

  const getCountriesCitiesSpecializations = async () => {
    try {
      const response = await fetch('/ajax/get-countries-cities-specializations', {
        method: 'POST',
      })

      if (!response) {
        throw new Error('Could not load the elements')
      }

      return await response.json()
    } catch (e) {
      console.log(e)
    }
  }

  return {
    getSpecializationsAndSummaries,
    ajaxFilterJobs,
    getCountriesCitiesSpecializations,
    specializationsAndSummaries,
    filteredSpecializationsSummaries,
    KeywordStr,
    embeddedJobsOpportunities,
    totalItems
  }
}

export default useAjaxCalls
