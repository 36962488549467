import { ref } from 'vue'

const checkedCountriesCopyForChips = ref([])

const useBlogFiltersFns = (
  urlParams,
  checkedCountries,
  countriesChipsMobile
) => {

  const setSelectedElementsFromUrl = (countries) => {
      for (const country of countries) {
          setTimeout(() => {
              checkedCountries.value = [...checkedCountries.value, country]
              checkedCountriesCopyForChips.value = [...checkedCountriesCopyForChips.value, country]
              countriesChipsMobile.value.push(country)
          }, 150)
      }
  }

  const getElementsFromUrl = () => {

      let countriesFromUrl = []
          const stringReplace = window.location.search.replace(/%20/g, ' ')
            .replace(/%23/g, '#')
            .replace(/%28/g, '(')
            .replace(/%29/g, ')')
            .replace(/%5B/g, '[')
            .replace(/%5D/g, ']')
            .replace(/%2F/g, '/')
            .replace(/%2C/g, ',')
            .replace(/%2E/g, '.')

      if (stringReplace.includes('country[]=')) {
          countriesFromUrl = getSubstring('&country[]=', ['&topic', '&page', '&utm'], stringReplace, '&country[]=');
      }

      setSelectedElementsFromUrl(countriesFromUrl)
  }

  const getSubstring = (startStr, endStr, str, splitBy) => {
      let pos = str.indexOf(startStr) + startStr.length

      for (const param of endStr) {
          if (str.includes(param)) {
              return str.substring(pos, str.indexOf(param, pos)).split(splitBy)
          }
      }

      return str.substring(pos).split(splitBy)
  }

  const removeCheckedItem = (type, index) => {
      if (type === 'country') {
        checkedCountries.value.splice(index, 1)
        checkedCountriesCopyForChips.value.splice(index, 1)
      }

      applyFilters('desktop')
  }

  const applyFilters = (version = null) => {
    let countriesUrl = ''

    if (version) {

      countriesUrl = checkedCountries.value.map(item => {
        return `&country[]=${item.replace(/,/g, '%2C').replace(/\(/g, '%28').replace(/\)/g, '%29')}`
      }).join('')

    }

    window.location.href = `${window.location.origin}${window.location.pathname}/?${countriesUrl}`
  }

  const clearFilters = () => {
      window.location.href = '/blog'
  }

  const updatePaginationLiks = () => {
      const paginationLinks = document.getElementsByClassName('pagination__link')

      if (paginationLinks.length > 0) {
          for (const pLink of paginationLinks) {
              if (pLink.getAttribute('href')) {
                  const pageStr = pLink?.textContent.trim()
                  let output = ``

                  if (window.location.search === '') {
                      output = `?page=${pageStr}`
                  } else {
                      output = window.location.search.includes('&page=')
                                  ? `${window.location.search.substring(0, window.location.search.lastIndexOf('&page='))}&page=${pageStr}`
                                  : window.location.search.includes('?page=')
                                  ? `${window.location.search.substring(0, window.location.search.lastIndexOf('?page='))}?page=${pageStr}`
                                  : `${window.location.search}&page=${pageStr}`
                  }
                  const isActive = pLink.classList.contains('active');
                  pLink.toggleAttribute('href', !isActive);

                  if (!isActive) {
                    const newHref = `${window.location.origin}${window.location.pathname}${output === '?page=1' ? '' : output}`;
                    pLink.setAttribute('href', newHref);
                  }
              }
          }
      }
  }

  return {
    getElementsFromUrl,
    removeCheckedItem,
    applyFilters,
    clearFilters,
    updatePaginationLiks,
    checkedCountries,
    checkedCountriesCopyForChips,
  }
}

export default useBlogFiltersFns
