import Splide, {PaginationData, PaginationItem} from "@splidejs/splide";

if (document.getElementById("splider-our-locations")) {
  const locationCarousels: NodeListOf<Element> = document.querySelectorAll("#splider-our-locations.splide")

  locationCarousels.forEach((carouselElement: any): void => {
    const splide: Splide = new Splide(carouselElement, {
      type: "loop",
      fixedWidth: "90%",
      height: "260px",
      gap: "15px",
      drag: true,
      arrows: false,
      breakpoints: {
        600: {
          height: "210px",
        },
      },
    })

    splide.on("pagination:mounted", function (data: PaginationData): void {
      data.list.classList.add("splide__pagination--custom")

      data.items.forEach(function (item: PaginationItem): void {
        item.button.classList.add("splide__pagination--custom__item")
      })
    })

    splide.mount()
  })
}
