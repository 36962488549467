<template>
    <div v-if="visible" class="locations-regions-header" id="anchors-header">
        <div class="anchors-header container">
            <div
                v-for="(anchorV, anchorK) in anchors"
                :key="anchorK"
                class="anchors-header__anchor"
                :class="[{ active: anchorK === activeBlock }]"
                @click="handleAnchorClick(anchorK)"
            >
                <a
                    :href="'#' + anchorK"
                    class="stretched-link text-decoration-none"
                    >{{ anchorV }}</a
                >
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted, onUnmounted} from "vue"
import { useWindowScroll } from "@vueuse/core"
const { gsap } = require("gsap")
const { ScrollToPlugin } = require("gsap/ScrollToPlugin")
gsap.registerPlugin(ScrollToPlugin)

const props = defineProps({
    anchors: {
        type: Object,
        required: true,
    }
})

const activeBlock = ref()
const whiteSection = document.getElementById("whiteSection").offsetTop
const { y } = useWindowScroll()
const locationSections = document.querySelectorAll('.locations__desktop-regions__region')

const visible = computed(() => {
    return window.innerWidth >= 1024
        ? y.value > whiteSection
            ? true
            : false
        : false
})

const clickedAnchor = ref(false)
const lastScrollTop = ref(0)

onMounted(() => {
    gsap.from("#anchors-header", {
        opacity: 0,
        y: -200,
        duration: 2,
    })
    document.addEventListener('scroll', handleScrolling)
})

onUnmounted(() => {
    document.removeEventListener('scroll', handleScrolling)
})

const handleScrolling = () => {
    let scrollFromTop = window.pageYOffset || document.documentElement.scrollTop

    const scrollingDown = scrollFromTop > lastScrollTop.value
    const scrollingUp = scrollFromTop < lastScrollTop.value
    const anchorClicked = clickedAnchor.value

    for (const item of locationSections) {
        const rect = item.getBoundingClientRect()

        if (scrollingDown) {
            if (rect.top <= 240 && rect.bottom >= 200 && !anchorClicked) {
                activeBlock.value = item.getAttribute('id')
            }
        } else if (scrollingUp) {
            if (rect.bottom >= 190 && rect.top > -350 && !anchorClicked) {
                activeBlock.value = item.getAttribute('id')
                break
            }
        }
    }

    lastScrollTop.value = scrollFromTop <= 0 ? 0 : scrollFromTop
}

const handleAnchorClick = (anchorKey) => {
    clickedAnchor.value = true
    scrollToSection(anchorKey)
}

function scrollToSection(anchorKey) {
    const sectionElement = document.getElementById(anchorKey);
    const sectionTopOffset = sectionElement.getBoundingClientRect().top + window.scrollY;
    const viewportHeight = window.innerHeight;
    const scrollToOffset = sectionTopOffset - viewportHeight * 0.25

    gsap.to(window, {
        duration: 0.01,
        scrollTo: { y: scrollToOffset, autoKill: false },
    });

    activeBlock.value = anchorKey;
    setTimeout(() => {
        clickedAnchor.value = false
    }, 850)
}
</script>
