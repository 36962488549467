export function enableThreeDotsBtns(): void {
  const shareJobBtn: Element[] = Array.from(document.getElementsByClassName("share-job-btn"))
  const allShareOptions: Element[] = Array.from(document.getElementsByClassName("share-job-options"))
  let lastOpenedShare: Element
  let currentOpenedShare: Element

  for (const btn of shareJobBtn) {
    btn.addEventListener('click', (e: MouseEvent): void => {
      e.preventDefault()
      localStorage.removeItem('shareJobMobile')

      setTimeout((): void => {
        currentOpenedShare = document.getElementById(`share-${btn.id}`)
        currentOpenedShare.classList.add('shown')
      }, 25)
    })
  }

  document.onclick = function (e: MouseEvent): void {
    const target: HTMLElement = e.target as HTMLElement
    const openedItem: any = target.dataset.jobid

    if (openedItem) {
      if (openedItem === lastOpenedShare) {
        e.preventDefault()
      } else {
        for (const shareOptions of allShareOptions) {
          shareOptions.classList.remove('shown')
        }
      }

      lastOpenedShare = openedItem
    } else {
      if (currentOpenedShare) {
        currentOpenedShare.classList.remove('shown')
      }
    }
  }
}

export function enableMobileShareBtns(): void {
  const overlay: Element = document.querySelector("#overlay-mobile")
  const mobileSharePanel: Element = document.getElementById("mobile-share-panel")
  const mobileShareBtn:  HTMLCollectionOf<Element> = document.getElementsByClassName("mobile-share-btn")

  for (const btn of Array.from(mobileShareBtn)) {
    btn.addEventListener("click", (e: MouseEvent): void => {
      e.preventDefault()
      mobileSharePanel.classList.add('show')
      overlay.classList.add('overlay-show')
        const element: HTMLElement = btn as HTMLElement
      if (element.dataset.link && element.dataset.title) {
        const sharejob: {title: string, link: string} = {
          title: element.dataset.title,
          link: element.dataset.link
        }

        localStorage.setItem('shareJobMobile', JSON.stringify(sharejob))
      }
    })
  }
}

export function enableShareSocialBtns(): void {
  const socialBtns: HTMLCollectionOf<Element> =document.getElementsByClassName('social-share')
  const jobPage: HTMLElement = document.getElementById('job-page')

  for (const btn of Array.from(socialBtns)) {
    btn.addEventListener('click', (e: MouseEvent): void => {
      e.preventDefault()
        const element: HTMLElement = btn as HTMLElement
      if (element.dataset.source === 'share-job-mobile' || element.dataset.source === 'share-job-desktop-job-page') {
        if (jobPage) {
          const title: string = document.getElementsByTagName('title')[0]?.textContent.trim()
          openSocialShare(element.dataset.network, title)
        } else if (localStorage.getItem('shareJobMobile') !== null) {
          const shareJobMobile = JSON.parse(localStorage.getItem('shareJobMobile'))
          const linkToShare: string = `${window.location.origin}${shareJobMobile.link}`
          openSocialShare(element.dataset.network, shareJobMobile.title, linkToShare)
        }
      }

      if (element.dataset.source === 'logeek-magazine') {
        const title: string = document.getElementById('latest-magazine')?.textContent.trim()
        openSocialShare(element.dataset.network, title)
      }

      if (element.dataset.source === 'blog-post') {
        const title: string = document.getElementsByTagName('title')[0]?.textContent.trim()
        openSocialShare(element.dataset.network, title)
      }

      if (element.dataset.source === 'share-job-desktop-listing-page') {
        let linkToShare: string = element.dataset.link

        if (element.dataset.network === 'refer') {
          window.location.href = `${linkToShare}`
        }

        linkToShare = `${window.location.origin}${element.dataset.link}`

        if (element.dataset.network === 'mail') {
          window.location.href = `mailto:?subject=Check%20out%20this%20job!&body=${linkToShare}`
        }

        const title: string = element.dataset.title
        openSocialShare(element.dataset.network, title, linkToShare)
      }
    })
  }
}

function openSocialShare(network: string, title: string, linkToShare: string = window.location.href): void {
  let socialLink

  switch (network) {
    case 'fb':
      socialLink = `http://www.facebook.com/share.php?title=${encodeURIComponent(title)}&u=`
      window.open(socialLink + encodeURIComponent(linkToShare), 'window', 'width=640,height=480,resizable,scrollbars')
      break
    case 'twitter':
      socialLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=`
      window.open(socialLink + encodeURIComponent(linkToShare), 'window', 'width=640,height=480,resizable,scrollbars')
      break
    case 'linkedin':
      socialLink = `https://www.linkedin.com/sharing/share-offsite/?url=${linkToShare}`
      window.open(socialLink, 'window', 'width=640,height=480,resizable,scrollbars')
      break
    case 'mail':
      socialLink = `mailto:?subject=Check%20out%20this%20job!&body=${encodeURIComponent(title)}%0A${linkToShare}`
      window.open(socialLink, 'window', 'width=640,height=480,resizable,scrollbars')
      break
  }
}
