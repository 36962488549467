export {}
const slider: HTMLElement = document.querySelector('.banner-text__wrapper')
const slides: NodeListOf<Element> = document.querySelectorAll('.banner-text__item')
const dotsContainer: Element = document.querySelector('.banner-text__dots')
let counter: number = 0
const slideWidth: number = slides.length > 0 ? slides[0].clientWidth : 0
let touchStartX: number = 0
let touchEndX: number = 0
let isMouseDown: boolean = false
let mouseX: number = 0

// Create dots
slides.forEach((_: Element, index: number): void => {
  const dot: HTMLSpanElement = document.createElement('span')
  dot.classList.add('dot')
  if (index === 0) dot.classList.add('active')
  dot.addEventListener('click', (): void => {
    goToSlide(index)
  })
  dotsContainer.appendChild(dot)
})

function goToSlide(index: number): void {
  counter = index
  slider.style.transform = `translateX(${-counter * slideWidth}px)`
  updateDots()
}

function updateDots(): void {
  const dots: NodeListOf<Element> = document.querySelectorAll('.dot')
  dots.forEach((dot: Element, index: number): void => {
    if (index === counter) {
      dot.classList.add('active')
    } else {
      dot.classList.remove('active')
    }
  })
}

function handleMouseMove(clientX: number): void {
    const mouseSwipeThreshold: number = 50 // Adjust as needed
    const swipeLength: number = clientX - mouseX
    if (swipeLength > mouseSwipeThreshold) {
        moveSlide('left')
        mouseX = clientX
    } else if (swipeLength < -mouseSwipeThreshold) {
        moveSlide('right')
        mouseX = clientX
    }
}

function handleSwipe(): void {
    const swipeThreshold: number = 50
    const swipeLength: number = touchEndX - touchStartX
    if (swipeLength > swipeThreshold) {
        moveSlide('left')
    } else if (swipeLength < -swipeThreshold) {
        moveSlide('right')
    }
}

function moveSlide(direction: string): void {
    if (direction === 'left') {
        counter--
    if (counter < 0) counter = slides.length - 1
    } else if (direction === 'right') {
        counter++
    if (counter === slides.length) counter = 0
    }

  slider.style.transform = `translateX(${-counter * slideWidth}px)`
  updateDots()
}

if (slider) {
    slider.addEventListener('touchstart', (event: TouchEvent): void => {
        touchStartX = event.touches[0].clientX
    })

    slider.addEventListener('touchend', (event: TouchEvent): void => {
        touchEndX = event.changedTouches[0].clientX
        handleSwipe()
    })

    slider.addEventListener('mousedown', (event: MouseEvent): void => {
        isMouseDown = true
        mouseX = event.clientX
    })

    slider.addEventListener('mousemove', (event: MouseEvent): void => {
        if (isMouseDown) {
          handleMouseMove(event.clientX)
        }
    })

    slider.addEventListener('mouseup', (): void => {
        isMouseDown = false
    })

    slider.addEventListener('mouseleave', (): void => {
        isMouseDown = false
    })
}
