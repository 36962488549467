(function (): void {
    let sliderWrap: Element = document.querySelector('.carousel-container'),
        slider: HTMLElement = sliderWrap?.querySelector('.carousel'),
        slideItems: NodeListOf<HTMLElement> = slider?.querySelectorAll('.item'),
        slideNavigation: NodeListOf<Element> = sliderWrap?.querySelectorAll('button[data-action]'),
        slideOrientation: number = 0,
        activeItemIndex: number = 0;

    function swipeCarousel(e: string = 'next'): void {
        document.querySelector("#card-" + activeItemIndex)?.classList.remove("active")

        if (e === "next") {
            slideOrientation -= 60
            if (++activeItemIndex > 5) {
                activeItemIndex = 0
            }
        } else if (e === "prev") {
            slideOrientation += 60
            if (--activeItemIndex < 0) {
                activeItemIndex = 5
            }
        }

        document.querySelector("#card-" + activeItemIndex)?.classList.add("active")

        if (slider instanceof HTMLElement && slideItems instanceof NodeList) {
            slider.style.webkitTransform = "rotateY(" + slideOrientation + "deg)"
            slider.style.transform = "rotateY(" + slideOrientation + "deg)"

            for (let i = 0; i < slideItems.length; i++) {
                slideItems[i].style.webkitTransform = "rotateY(" + -slideOrientation + "deg)"
                slideItems[i].style.transform = "rotateY(" + -slideOrientation + "deg)"
            }
        }
    }

    let autoSwipe: NodeJS.Timeout = setInterval(function (): void {
        swipeCarousel('next')
    }, 99999)

    slideNavigation?.forEach((btn: Element): void => {
        btn.addEventListener('click', function (e: any): void {
            swipeCarousel(e.currentTarget?.dataset?.action)
            clearInterval(autoSwipe)
            autoSwipe = setInterval(swipeCarousel, 99999)
        })
    })

    const formBlock: Element = document.querySelector('.form-container')
    if (formBlock instanceof HTMLElement && !formBlock.querySelector('form[name="iykyk"]')) {
        formBlock.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        })
    }
})()
