if (window.location.pathname !== '/iykyk') {
    document.getElementById('global-search').addEventListener('submit', (e: SubmitEvent): void => {
        if ((<HTMLInputElement>document.getElementById('input-search')).value.trim() === '') {
            e.preventDefault()
        }
    })

    document.getElementById('global-search-mobile').addEventListener('submit', (e: SubmitEvent): void => {
        if ((<HTMLInputElement>document.getElementById('input-search-mobile')).value.trim() === '') {
            e.preventDefault()
        }
    })
}
