import Splide, {PaginationData, PaginationItem} from "@splidejs/splide";

const eCalendarSplider1: HTMLElement = document.getElementById('single-location-e-calendar-splider-1')
const eCalendarSplider2: HTMLElement = document.getElementById('single-location-e-calendar-splider-2')
const eCalendarSplider3: HTMLElement = document.getElementById('single-location-e-calendar-splider-3')
const eCalendarSplider4: HTMLElement = document.getElementById('single-location-e-calendar-splider-4')

if (eCalendarSplider1) {
    const spliderECalendar1: NodeListOf<Element> = document.querySelectorAll("#single-location-e-calendar-splider-1.splide")

    spliderECalendar1.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'loop',
            gap: "20px",
            drag: true,
            arrows: true,
            perMove: 1,
            perPage: 3,
            breakpoints: {
                1440: {
                    perPage: 2
                },
                1023: {
                    perPage: 1
                }
            }
        })

        splide.on("pagination:mounted", function (data: PaginationData): void {
            data.list.classList.add("splide__pagination--custom")

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add("splide__pagination--custom__item")
            })
        })

        splide.mount()
    })
}

if (eCalendarSplider2) {
    const spliderECalendar2: NodeListOf<Element> = document.querySelectorAll("#single-location-e-calendar-splider-2.splide")

    spliderECalendar2.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'loop',
            gap: "20px",
            drag: true,
            arrows: true,
            perMove: 1,
            perPage: 3,
            breakpoints: {
                1440: {
                    perPage: 2
                },
                1023: {
                    perPage: 1
                }
            }
        })

        splide.on("pagination:mounted", function (data: PaginationData): void {
            data.list.classList.add("splide__pagination--custom")

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add("splide__pagination--custom__item")
            })
        })

        splide.mount()
    })
}

if (eCalendarSplider3) {
    const spliderECalendar3: NodeListOf<Element> = document.querySelectorAll("#single-location-e-calendar-splider-3.splide")

    spliderECalendar3.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'loop',
            gap: "20px",
            drag: true,
            arrows: true,
            perMove: 1,
            perPage: 3,
            breakpoints: {
                1440: {
                    perPage: 2
                },
                1023: {
                    perPage: 1
                }
            }
        })

        splide.on("pagination:mounted", function (data: PaginationData): void {
            data.list.classList.add("splide__pagination--custom")

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add("splide__pagination--custom__item")
            })
        })

        splide.mount()
    })
}

if (eCalendarSplider4) {
    const spliderECalendar4: NodeListOf<Element> = document.querySelectorAll("#single-location-e-calendar-splider-4.splide")

    spliderECalendar4.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'loop',
            gap: "20px",
            drag: true,
            arrows: true,
            perMove: 1,
            perPage: 3,
            breakpoints: {
                1440: {
                    perPage: 2
                },
                1023: {
                    perPage: 1
                }
            }
        })

        splide.on("pagination:mounted", function (data: PaginationData): void {
            data.list.classList.add("splide__pagination--custom")

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add("splide__pagination--custom__item")
            })
        })

        splide.mount()
    })
}
