import Splide, {PaginationData, PaginationItem} from "@splidejs/splide"

const ourSpeakersD: HTMLElement = document.getElementById('our-speakers-desktop')
const ourSpeakersM: HTMLElement = document.getElementById('our-speakers-mobile')

if (
    ourSpeakersD &&
    ourSpeakersM
) {
    ourSpeakersD.classList.add('row')
    const speakersItems: NodeListOf<Element> = ourSpeakersD.querySelectorAll('.our-speakers-desktop .single-event__our-speakers--item')
    const speakersList: HTMLCollectionOf<Element> = ourSpeakersM.getElementsByClassName('splide__list')

    if (
        speakersItems.length > 0 &&
        speakersList.length > 0
    ) {
        speakersItems.forEach((speakerItem: Element): void => {
            speakerItem.classList.add('splide__slide')
            speakersList[0].append(speakerItem.cloneNode(true))
        })
    }

    setTimeout(function(): void {
        const mobileSpeakersSplide: NodeListOf<Element> = document.querySelectorAll("#our-speakers-mobile.splide")

        mobileSpeakersSplide.forEach((item: any): void => {
            const splide: Splide = new Splide(item, {
                type: speakersItems.length === 1 ? 'slide' : 'loop',
                gap: "20px",
                drag: true,
                arrows: false,
                perMove: 1,
                perPage: 3,
                breakpoints: {
                    1023: {
                        perPage: 1
                    }
                }
            })

            splide.on("pagination:mounted", function (data: PaginationData): void {
                data.list.classList.add("splide__pagination--custom")

                data.items.forEach(function (item: PaginationItem): void {
                    item.button.classList.add("splide__pagination--custom__item")
                })
            })

            splide.mount()
        })
    }, 500)
}
