<template>
    <a :href="bookmark.url" class="jobs__list__job">
        <div class="jobs__list__job__details">
            <h2 class="subtitle-l text-rich-black">
                {{ bookmark.title }}
            </h2>
            <p class="body-m-regular text-dark-gray">
                {{ bookmark.subtitle }}
            </p>
            <div class="jobs__list__job__details__tags">
                <div class="jobs__list__job__details__tags__location text-rich-black">
                    <img class="pin-icon" src="/theme/luxoft/assets/images/icons/grey-pin.svg" alt="pin icon">
                    <p class="body-s-regular">
                        {{ bookmark.country }}
                    </p>

                    <img src="/theme/luxoft/assets/images/icons/grey-dot.svg" alt="dot icon">
                    <p class="body-s-regular">
                        {{ bookmark.location }}
                    </p>
                </div>
                <div class="jobs__list__job__details__tags__hotjob">
                    <p class="body-s-regular text-dark-gray">
                        Hot jobs
                    </p>
                </div>
            </div>
        </div>
        <div class="jobs__list__job__buttons">
            <div class="jobs__list__job__buttons__icon" :data-job="JSON.stringify(bookmark)" @click.prevent="() => bookmarkClicked(bookmark.url)">
                <img src="/theme/luxoft/assets/images/icons/bookmark-purple-remove.svg" alt="remove icon">
            </div>
            <div class="jobs__list__job__buttons__icon">
                <button class="share-job-btn" :id="index">
                    <img src="/theme/luxoft/assets/images/icons/vertical-dots.svg" alt="vertical dots" :data-jobid="`${bookmark.url}`">
                </button>
            </div>
            <div class="share-job-options" :id="`share-${index}`">
                <div class="share-job-options__desktop">
                    <button class="share-job-option social-share" data-network="refer" data-source="share-job-desktop-listing-page" :data-title="bookmark.title" :data-link="`https://referral.luxoft.com/referrer/recommendations/new?vacancy[name]=${encodeURIComponent(escapeHtmlEntities(bookmark.title))}&vacancy[code]=${encodeURIComponent(escapeHtmlEntities(bookmark.vrPkey))}&vacancy[specialization]=${encodeURIComponent(escapeHtmlEntities(bookmark.specialization))}&vacancy[country]=${encodeURIComponent(escapeHtmlEntities(bookmark.country))}&vacancy[city]=${encodeURIComponent(escapeHtmlEntities(bookmark.city))}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--refer">
                            <img src="/theme/luxoft/assets/images/icons/user.svg" alt="user icon">
                        </div>
                        Refer a friend
                    </button>
                    <button class="share-job-option social-share" data-network="fb" data-source="share-job-desktop-listing-page" :data-title="bookmark.title" :data-link="`${bookmark.url}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--facebook">
                            <svg>
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#facebook-icon"></use>
                            </svg>
                        </div>
                        Facebook
                    </button>
                    <button class="share-job-option social-share" data-network="twitter" data-source="share-job-desktop-listing-page" :data-title="bookmark.title" :data-link="`${bookmark.url}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--twitter">
                            <svg>
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#twitter-icon"></use>
                            </svg>
                        </div>
                        Twitter
                    </button>
                    <button class="share-job-option social-share" data-network="linkedin" data-source="share-job-desktop-listing-page" :data-title="bookmark.title" :data-link="`${bookmark.url}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--linkedin">
                            <svg>
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#linkedin-icon"></use>
                            </svg>
                        </div>
                        Linkedin
                    </button>
                    <button class="share-job-option social-share" data-network="mail" data-source="share-job-desktop-listing-page" :data-title="bookmark.title" :data-link="`${bookmark.url}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--mail">
                            <svg>
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#mail-icon"></use>
                            </svg>
                        </div>
                        Mail
                    </button>
                </div>
                <div class="share-job-options__mobile">
                    <button class="share-job-option job-bookmark job-bookmark-list" :data-job="getJobData(bookmark)">
                        <img src="/theme/luxoft/assets/images/icons/favorite-purple.svg" alt="favorite icon">
                        Add to favorites
                    </button>
                    <button class="share-job-option social-share" data-network="refer" data-source="share-job-desktop-listing-page" :data-title="bookmark.title" :data-link="`https://referral.luxoft.com/referrer/recommendations/new?vacancy[name]=${encodeURIComponent(escapeHtmlEntities(bookmark.title))}&vacancy[code]=${encodeURIComponent(escapeHtmlEntities(bookmark.vrPkey))}&vacancy[specialization]=${encodeURIComponent(escapeHtmlEntities(bookmark.specialization))}&vacancy[country]=${encodeURIComponent(escapeHtmlEntities(bookmark.country))}&vacancy[city]=${encodeURIComponent(escapeHtmlEntities(bookmark.city))}`">
                        <img src="/theme/luxoft/assets/images/icons/refer.svg" alt="refer icon">
                        Refer a friend
                    </button>
                    <button class="share-job-option mobile-share-btn" :data-title="bookmark.title" :data-link="`/jobs/${bookmark.url}`">
                        <img src="/theme/luxoft/assets/images/icons/share.svg" alt="share icon">
                        Share
                    </button>
                </div>
            </div>
        </div>
    </a>
</template>
<script>
export default {
    props: {
        bookmark: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true,
            default: 0,
        }
    },
    methods: {
        bookmarkClicked(url) {
            const bookmarks = JSON.parse(localStorage.bookmarks) || []
            const bookmarkIndex = bookmarks.findIndex( (item) => url === item?.url)

            if (bookmarkIndex !== -1) {
                bookmarks.splice(bookmarkIndex, 1)
            }
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks))
            this.$emit('bookmark-clicked', url)
            handleCurrentBookmarksCount()
        },
        getJobData(job) {
            const data = {
                'title': job.title,
                'subtitle': job.specialization,
                'city': job.city,
                'location': job.country,
                'url': `/jobs/${job.url}`
            }

            return JSON.stringify(data)
        },
        escapeHtmlEntities(str) {
            return String(str).replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#39;')
        }
    }
}
</script>
