import Splide, {PaginationData, PaginationItem} from "@splidejs/splide";

function initializeSplide(carouselElement): void {
  const splide: Splide = new Splide(carouselElement, {
    type: "loop",
    fixedWidth: "90%",
    height: "260px",
    gap: "15px",
    drag: true,
    arrows: false,
    breakpoints: {
      600: {
        height: "210px",
      },
    },
  })

  splide.on('pagination:mounted', function (data): void {
    data.list.classList.add('splide__pagination--custom')

    data.items.forEach(function (item): void {
      item.button.classList.add('splide__pagination--custom__item')
    })
  })

  splide.on('ready', function (): void {
    // Fixes Safari problem with svg rendering
    const svgsInCarousel = carouselElement.querySelectorAll('svg')
    svgsInCarousel.forEach((svg): void => {
      // Toggle the 'display' property to trigger a redraw
      svg.style.display = 'none'
      setTimeout((): void => {
        svg.style.display = 'block'
      }, 100)
    })
  })

  splide.mount()
}

if (document.getElementsByClassName('locations')[0]) {
  const accordions: NodeListOf<Element> = document.querySelectorAll('.accordion-collapse')

  document
    .getElementById('regions-accordion')
    .style.setProperty('margin-bottom', '0', 'important')

  accordions.forEach((accordion: Element, index: number): void => {
    let initialized: boolean = false; // Flag to track initialization
    const carouselElement: Element = accordion.querySelector('.splide') // Find the carousel

    if (carouselElement) {
      if (index === 0) {
        // Explicitly initialize the first carousel at page load
        initializeSplide(carouselElement)
        initialized = true
      }

      accordion.addEventListener("shown.bs.collapse", function (): void {
        // Check if the carousel has already been initialized
        if (!initialized) {
          initialized = true

          // Initialize the Splide carousel
          initializeSplide(carouselElement)
        }
      })
    }
  })
}

const locationVideoSplider: HTMLElement = document.getElementById('single-location-video-splider')

if (locationVideoSplider) {
  const spliderLocationVideos: NodeListOf<Element> = document.querySelectorAll("#single-location-video-splider.splide")

  spliderLocationVideos.forEach((item: any): void => {
    const splide: Splide = new Splide(item, {
      type: 'loop',
      gap: "20px",
      drag: true,
      arrows: true,
      perMove: 1,
      perPage: 3,
      breakpoints: {
        1440: {
          perPage: 2
        },
        1023: {
          perPage: 1
        }
      }
    })

    splide.on("pagination:mounted", function (data: PaginationData): void {
      data.list.classList.add("splide__pagination--custom")

      data.items.forEach(function (item: PaginationItem): void {
        item.button.classList.add("splide__pagination--custom__item")
      })
    })

    splide.mount()
  })
}

const locationLocationsSplider: HTMLElement = document.getElementById('single-location-locations-splider')
const locationOfficesD:HTMLElement  = document.getElementById('single-location-offices-desktop')

if (
  locationLocationsSplider &&
  locationOfficesD
) {

  const officesItems: NodeListOf<Element> = locationOfficesD.querySelectorAll('.single-location__offices--wrapper .tab-pane__content')
  const locationOfficesList: Element = locationLocationsSplider.querySelectorAll('.splide__list')[0]
  const tabsTitles: NodeListOf<Element> = locationOfficesD.querySelectorAll('.single-location__offices--wrapper .tabs-section .nav-link')

  if (
    officesItems.length > 0 &&
    tabsTitles.length > 0 &&
    locationOfficesList
  ) {
    officesItems.forEach((office: Element, index: number): void => {
      office.classList.add('splide__slide')
      const titleItem: HTMLParagraphElement = document.createElement('p')
      titleItem.classList.add('title')
      titleItem.classList.add('mb-1')

      const textNode: Text = document.createTextNode(tabsTitles[index].textContent)
      titleItem.appendChild(textNode)

      office.prepend(titleItem.cloneNode(true))
      locationOfficesList.append(office.cloneNode(true))
    })

    mountLocationsSlider(officesItems.length)
  }
}

function mountLocationsSlider(amount: number): void {
  const spliderLocationLocations: NodeListOf<Element> = document.querySelectorAll(
    "#single-location-locations-splider.splide"
  )

  spliderLocationLocations.forEach((item: any): void => {

    const splide: Splide = new Splide(item, {
      type: amount === 1 ? 'slide' : 'loop',
      gap: "20px",
      drag: true,
      arrows: true,
      perMove: 1,
      perPage: 1,
    })

    splide.on('pagination:mounted', function (data: PaginationData): void {
      data.list.classList.add('splide__pagination--custom')

      data.items.forEach(function (item: PaginationItem): void {
        item.button.classList.add('splide__pagination--custom__item')
      })
    })

    splide.mount()
  })
}
