<template>
    <div class="single-search">
        <div class="single-search__content">
            <h5 class="title" v-html="getItemTitle(item)" />
            <p class="excerpt" v-html="generateDescription(item.id)" />
            <a :href="generateAbsolutePageUrl(item.id)">
                {{ generateAbsolutePageUrl(item.id) }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
            default: () => [],
        },
        urls: {
            type: Object,
            required: true,
            default: () => [],
        },
        excerpts: {
            type: Object,
            required: true,
            default: () => [],
        }
    },
    setup(props) {

        const generateDescription = (itemId) => {
            const found = props.excerpts.find(url => url.id === itemId)
            const removeAllBetweenBraces = found.excerpt.replace(/{.*}/, '')
            return removeAllBetweenBraces.replace(/%|{|}/g, '')
        }

        const generateAbsolutePageUrl = (itemId) => {
            const found = props.urls.find(url => url.id === itemId)
            return found.absolute_url
        }

        const getItemTitle = (item) => {
            return item.fieldValues.title.en || item.fieldValues.title
        }

        return {
            getItemTitle,
            generateDescription,
            generateAbsolutePageUrl
        }
    }
}
</script>
