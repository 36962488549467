import Splide, {PaginationData, PaginationItem} from "@splidejs/splide";

const primaryProjectRoles:HTMLElement  = document.getElementById('primary-project-roles')
const primaryProjectRolesSplide: HTMLElement = document.getElementById('primary-project-roles-splider')

if (
    primaryProjectRolesSplide &&
    primaryProjectRoles
) {

    const primaryProjectRolesItems: NodeListOf<Element> = primaryProjectRoles.querySelectorAll('.primary-project-roles__roles--item')
    const splideList: Element = primaryProjectRolesSplide.querySelectorAll('.splide__list')[0]

    if (
        primaryProjectRolesItems.length > 0 &&
        splideList
    ) {
        primaryProjectRolesItems.forEach((project: Element, index: number): void => {
            const splideDiv: HTMLDivElement = document.createElement('div')
            splideDiv.classList.add('splide__slide')
            splideDiv.append(project.cloneNode(true))
            splideList.append(splideDiv)
        })

        mountPrimaryProjectsSplide()
    }
}

function mountPrimaryProjectsSplide(): void {
    const splidePrimaryProjectRoles: NodeListOf<Element> = document.querySelectorAll(
        "#primary-project-roles-splider.splide"
    )

    splidePrimaryProjectRoles.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'loop',
            gap: "20px",
            drag: true,
            arrows: true,
            perMove: 1,
            perPage: 1,
        })

        splide.on('pagination:mounted', function (data: PaginationData): void {
            data.list.classList.add('splide__pagination--custom')

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add('splide__pagination--custom__item')
            })
        })

        splide.mount()
    })
}
