<script setup>
import {onMounted, watch, ref, computed} from 'vue'
import useAjaxCalls from "../functions/apiCalls";
const searchWord = ref('')
const cities = ref([])
const countries = ref([])
const specializations = ref([])
const keywordSearchPanel = ref(null)
const keywordSearchInput = ref(null)

const {
    getCountriesCitiesSpecializations
} = useAjaxCalls()

const openKeywordSearchMobile = () => {
    if (window.innerWidth <= 1023) {
        keywordSearchPanel.value.classList.add('show')
        keywordSearchInput.value.focus()

        setTimeout(() => {
            if (keywordSearchPanel.value.getBoundingClientRect().top !== 0) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
        }, 300)
    }
}

const submitFormHandler = () => {
    let urlWithKeyword =  `/jobs`

    if (searchWord.value.trim() !== '') {
        urlWithKeyword += `?keyword=${searchWord.value.trim().replace(/#/g, '%23').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/,/g, '%2C').replace(/\//g, '%2F').replace(/\./g, '%2E').replace(/\+/g, '%2B')}`
    }

    window.location.href = urlWithKeyword
    searchWord.value = ''
}

const getAllCountriesCitiesAndSpecializations = async () => {
    const res = await getCountriesCitiesSpecializations()

    if (res?.data) {
        cities.value = res.data.cities
        countries.value = res.data.countries
        specializations.value = res.data.specializations
    }
}

onMounted(() => {
    getAllCountriesCitiesAndSpecializations()
})

const foundSpecializations = computed( () => {
    return specializations.value.filter(item => item.toLowerCase().startsWith(searchWord.value.toLowerCase()) && searchWord.value.trim() !== '')
})

const foundCities = computed( () => {
    return cities.value.filter(item => item.toLowerCase().startsWith(searchWord.value.toLowerCase()) && searchWord.value.trim() !== '')
})

const foundCountries = computed( () => {
    return countries.value.filter(item => item.toLowerCase().startsWith(searchWord.value.toLowerCase()) && searchWord.value.trim() !== '')
})

</script>

<template>
    <div class="job-search">
        <form
            class="career-search"
            :class="{ 'showing-results': searchWord.trim() !== '' }"
            @submit.prevent="submitFormHandler"
        >
            <label for="search-word"></label>
            <input class="career-search__input" v-model="searchWord" @focus="openKeywordSearchMobile" placeholder="Search job title or location" name="search-word">
            <button type="submit" class="btn-normal text-uppercase">Search</button>
        </form>
        <div class="d-flex align-items-center top-searched-roles">
            <p class="top-searched-roles__label">Popular search:</p>
            <div class="top-searched-roles__role"><a href="/jobs?keyword=&specialization[]=Java">Java developer</a></div>
            <div class="top-searched-roles__role"><a href="/jobs?keyword=qa">QA</a></div>
            <div class="top-searched-roles__role"><a href="/jobs?keyword=react">React</a></div>
        </div>
    </div>
    <div
        class="home-search-results"
        :class="{ 'active': searchWord.trim() !== '' }"
    >
        <div class="home-search-results__wrapper">
            <template v-if="foundSpecializations.length > 0 || foundCities.length > 0 || foundCountries.length > 0">
                <template v-for="specialization in foundSpecializations">
                    <a :href="`/jobs?keyword=&specialization[]=${specialization}`" class="result-item">
                        <img src="/theme/luxoft/assets/images/icons/search-gray.svg" alt="search icon">
                        <p>{{ specialization }}</p>
                    </a>
                </template>
                <template v-for="city in foundCities">
                    <a :href="`/jobs?keyword=&city[]=${city}`" class="result-item">
                        <img src="/theme/luxoft/assets/images/icons/search-gray.svg" alt="search icon">
                        <p>Job opportunities in {{ city }}</p>
                    </a>
                </template>
                <template v-for="country in foundCountries">
                    <a :href="`/jobs?keyword=&country[]=${country}`" class="result-item">
                        <img src="/theme/luxoft/assets/images/icons/search-gray.svg" alt="search icon">
                        <p>Job opportunities in {{ country }}</p>
                    </a>
                </template>
            </template>
            <template v-else>
                <div class="nothing-found">
                    <img src="/theme/luxoft/assets/images/icons/nothing-found.svg" alt="nothing-found icon">
                    <p>Nothing found, change the search parameters</p>
                </div>
            </template>
        </div>
    </div>

    <Teleport to="body">
        <div class="jobs-keyword-search-panel" ref="keywordSearchPanel">
            <div class="jobs-keyword-search-panel__input-section mt-2">
                <button @click="keywordSearchPanel.classList.remove('show')" class="back-btn">
                    <img src="/theme/luxoft/assets/images/icons/gray-arrow.svg" width="24" height="24" alt="gray-arrow icon">
                </button>
                <div class="filter-input-search mt-2">
                    <img src="/theme/luxoft/assets/images/icons/search-gray.svg" alt="search icon">
                    <input type="text" name="searchWord" placeholder="Search job title or location" v-model="searchWord" ref="keywordSearchInput">
                    <button @click="searchWord = ''">
                        <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close search icon">
                    </button>
                </div>
            </div>
            <div class="jobs-keyword-search-panel__results mt-2 home-search">
                <ul v-if="foundSpecializations.length > 0 || foundCities.length > 0 || foundCountries.length > 0">
                    <li v-for="specialization in foundSpecializations">
                        <a :href="`/jobs?keyword=&specialization[]=${specialization}`">
                            <img src="/theme/luxoft/assets/images/icons/search-gray.svg" alt="search icon">
                            <p>{{ specialization }}</p>
                        </a>
                    </li>
                    <li v-for="city in foundCities">
                        <a :href="`/jobs?keyword=&city[]=${city}`">
                            <img src="/theme/luxoft/assets/images/icons/search-gray.svg" alt="search icon">
                            <p>Job opportunities in {{ city }}</p>
                        </a>
                    </li>
                    <li v-for="country in foundCountries">
                        <a :href="`/jobs?keyword=&country[]=${country}`">
                            <img src="/theme/luxoft/assets/images/icons/search-gray.svg" alt="search icon">
                            <p>Job opportunities in {{ country }}</p>
                        </a>
                    </li>
                </ul>
                <div v-else class="nothing-found">
                    <img src="/theme/luxoft/assets/images/icons/nothing-found.svg" alt="nothing-found icon">
                    <p>Nothing found, change the search parameters</p>
                </div>
            </div>
        </div>
    </Teleport>
</template>
