const eventForm = document.getElementById("event-form")
let isValid = true

if (eventForm) {
  const urlParams = new URLSearchParams(window.location.search)
  document.getElementById("utm-campaign").value = urlParams.get("utm_campaign") || ""
  document.getElementById("utm-medium").value = urlParams.get("utm_medium") || ""
  document.getElementById("utm-source").value = urlParams.get("utm_source") || ""
  document.getElementById("utm-traffic-source").value = urlParams.get("utm_traffic_source") || ""
  document.getElementById("utm-content").value = urlParams.get("utm_content") || ""
  document.getElementById("utm-term").value = urlParams.get("utm_term") || ""

  const linkedinInput = document.getElementById("linkedin-link")
  let linkedinStatus = null
  if (linkedinInput) {
    linkedinStatus = document.getElementById(
        `form-error-${linkedinInput.dataset["index"]}`
    )
  }

  if (linkedinInput && linkedinStatus) {
    linkedinInput.addEventListener("input", function () {
      const urlInput = this.value
      const urlPattern = /^(https:\/\/)?(www\.)?linkedin\.com\/[^\s/$.?#].[^\s]*$/
      if (urlPattern.test(urlInput)) {
        linkedinStatus.classList.remove("hidden")
        linkedinStatus.querySelector("p").textContent = "Valid!"
        linkedinStatus.classList.add("success")
      } else {
        linkedinStatus.classList.remove("hidden")
        linkedinStatus.classList.remove("success")
        linkedinStatus.querySelector("p").textContent = "Please enter a valid URL!"
      }
      if (!urlInput.length) {
        linkedinStatus.classList.add("hidden")
      }
    })
  }

  const fileInputIds = Array.from(
    document.querySelectorAll('input[type="file"]')
  ).map((input) => input.getAttribute("id"))

  if (fileInputIds.length) {
    fileInputIds.forEach((id) => {
      const fileInput = document.getElementById(id)
      const errorMessage = document.getElementById(
        `form-error-${fileInput.dataset["index"]}`
      )
      fileInput.addEventListener("change", () => {
        const maxFileSize = 1 * 1024 * 1024 // 1MB in bytes

        if (fileInput.files.length > 0) {
          const fileName = fileInput.files[0].name
          const fileSize = fileInput.files[0].size

          if (fileSize > maxFileSize) {
            errorMessage.querySelector("p").textContent =
              "File size exceeds the allowed limit (1MB)."
            errorMessage.classList.remove("hidden")
            errorMessage.classList.remove("success")
            fileInput.value = ""
          } else {
            errorMessage.classList.add("success")
            errorMessage.classList.remove("hidden")
            errorMessage.querySelector("p").textContent = `File "${fileName}" is attached`
          }
        }
      })
    })
  }

  const submitBtn = document.getElementById('submit-button')

  if (submitBtn) {
    submitBtn.addEventListener("click", function (event) {
      isValid = true
      const recaptchaRes = grecaptcha.getResponse()

      if (!recaptchaRes) {
        isValid = false
        document.getElementById('reCAPTCHA-error').classList.remove('hidden')
      }

      // Validation for text fields
      const textFields = eventForm.querySelectorAll('input[type="text"]')
      textFields.forEach(function (textField) {
        if (textField.hasAttribute("required") && textField.value.trim() === "") {
          isValid = false
          showErrorMessage(textField)
        } else {
          hideErrorMessage(textField)
        }
      })

      // Validation for tel fields
      const telFields = eventForm.querySelectorAll('input[type="tel"]')
      telFields.forEach(function (telField) {
        if (telField.hasAttribute("required") && telField.value.trim() === "") {
          isValid = false
          showErrorMessage(telField)
        } else {
          hideErrorMessage(telField)
        }
      })

      // Validation for number fields
      const numberField = eventForm.querySelectorAll('input[type="number"]')
      numberField.forEach(function (numberFiled) {
        if (
          numberFiled.hasAttribute("required") &&
          numberFiled.value.trim() === ""
        ) {
          isValid = false
          showErrorMessage(numberFiled)
        } else {
          hideErrorMessage(numberFiled)
        }
      })

      const textAreaFields = eventForm.querySelectorAll("textarea")
      textAreaFields.forEach(function (textAreaFields) {
        if (
          textAreaFields.hasAttribute("required") &&
          textAreaFields.value.trim() === ""
        ) {
          isValid = false
          showErrorMessage(textAreaFields)
        } else {
          hideErrorMessage(textAreaFields)
        }
      })

      const emailFields = eventForm.querySelectorAll('input[type="email"]')
      emailFields.forEach(function (emailField) {
        if (
          emailField.hasAttribute("required") &&
          emailField.value.trim() === ""
        ) {
          isValid = false
          showErrorMessage(emailField)
        } else if (
          emailField.hasAttribute("required") &&
          !isValidEmail(emailField.value.trim())
        ) {
          isValid = false
          showErrorMessage(emailField, "Enter a valid email address")
        } else {
          hideErrorMessage(emailField)
        }
      })

      const selectFields = eventForm.querySelectorAll("select")
      selectFields.forEach(function (selectField) {
        if (selectField.dataset["required"] && selectField.value === "") {
          isValid = false
          showErrorMessage(selectField)
        } else {
          hideErrorMessage(selectField)
        }
      })

      // Validation for file inputs
      const fileInputIds = Array.from(
        document.querySelectorAll('input[type="file"]')
      ).map((input) => input.getAttribute("id"))

      if (fileInputIds.length) {
        fileInputIds.forEach((id) => {
          var fileInput = document.getElementById(id)
    
          if (fileInput.dataset["required"] && fileInput.files.length === 0) {
            // File input is required but no file is selected
            isValid = false
            showErrorMessage(fileInput)
          } else {
            hideErrorMessage(fileInput, true)
          }
        })
      }
    })
  }

  function isValidEmail(email) {
    // Add your email validation logic here
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  function showErrorMessage(field, message = "") {
    var index = field.getAttribute("data-index")
    var errorContainer = document.getElementById("form-error-" + index)
    if (errorContainer) {
      if (message) {
        errorContainer.querySelector("p").textContent = message
      }

      errorContainer.classList.remove("hidden")
    }
  }

  function hideErrorMessage(field, keep = false) {
    var index = field.getAttribute("data-index")
    var errorContainer = document.getElementById("form-error-" + index)
    if (errorContainer && !keep) {
      errorContainer.classList.add("hidden")
    }
  }

  eventForm.addEventListener('submit', function (e) {
    e.preventDefault()

    const formData = new FormData(this)
    const formError = document.getElementById('form-error')
    const recordSlug = formData.get("event_slug")
    const pageId = formData.get("page_id")
    const recaptchaResponse = grecaptcha.getResponse()

    if (!recaptchaResponse) isValid = false

    if (isValid) {
      submitBtn.setAttribute('disabled', 'true')

      fetch('/ajax/save-form', {
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if (data.status !== "success") {
          formError.textContent = `Error: ${data.message}`
          submitBtn.removeAttribute('disabled')
          return
        }

        const targetURL = [window.location.pathname, 'registration-thank-you'];


        window.location.href = targetURL.join('/')
      })
      .catch(error => {
        submitBtn.removeAttribute('disabled')
        let message = error.message
        if (error.response && error.response.status === 422) {
          const response = error.response.json()
          if (response && response.message) {
            message = response.message
          }
        }

        formError.textContent = `Error: ${message}`
      })
    }
  })

  const dropdownModalElement = document.getElementById("dropdown-modal")
  const dropdownModal = new bootstrap.Modal("#dropdown-modal", {})
  dropdownModalElement.addEventListener("show.bs.modal", (event) => {
    // console.log(event)
  })

  var x, i, j, l, ll, selElmnt, a, b, c
  /* Look for any elements with the class "custom-select": */
  x = document.getElementsByClassName("custom-select")
  l = x.length
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0]
    ll = selElmnt.length
    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV")
    a.setAttribute("class", "select-selected")
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML
    x[i].appendChild(a)

    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV")
    b.setAttribute("class", "select-items select-hide")
    for (j = 0; j < ll; j++) {
      /* For each option in the original select element,
    create a new DIV that will act as an option item: */
      c = document.createElement("DIV")
      c.innerHTML = selElmnt.options[j].innerHTML
      c.addEventListener("click", function (e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        var y, i, k, s, h, sl, yl
        s = this.parentNode.parentNode.getElementsByTagName("select")[0]
        sl = s.length
        h = this.parentNode.previousSibling
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i
            h.innerHTML = this.innerHTML
            y = this.parentNode.getElementsByClassName("same-as-selected")
            yl = y.length
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class")
            }
            this.setAttribute("class", "same-as-selected")
            break
          }
        }
        h.click()
      })
      b.appendChild(c)
    }
    x[i].appendChild(b)
    a.addEventListener("click", function (e) {
      if (window.innerWidth >= 1024) {
        /* When the select box is clicked, close any other select boxes,
      and open/close the current select box: */
        e.stopPropagation()
        closeAllSelect(this)
        this.nextSibling.classList.toggle("select-hide")
        this.classList.toggle("select-arrow-active")
      } else {
        const clickedSelect = this.parentElement.querySelector("select")
        ll = clickedSelect.length

        const modalTitle = document.getElementById("dropdown-modal-label")
        const labelText =
          clickedSelect.parentNode.parentNode.getElementsByTagName("label")[0]
            ?.innerHTML || "Currency" // if the dropwdown is placed elsewhere than the other dropdowns in dom. Atm, only the currency one behaves like this
        modalTitle.textContent = labelText.split(" ").slice(0, 3).join(" ")

        /* For each element, create a new DIV that will contain the option list: */
        b = document.createElement("DIV")
        b.setAttribute("class", "select-items select-hide")
        for (j = 0; j < ll; j++) {
          /* For each option in the original select element,
    create a new DIV that will act as an option item: */
          c = document.createElement("DIV")
          c.innerHTML = clickedSelect.options[j].innerHTML
          c.addEventListener("click", function (e) {
            /* When an item is clicked, update the original select box,
        and the selected item: */
            var y, i, k, s, h, sl, yl
            s = clickedSelect
            sl = s.length
            h = clickedSelect.parentNode.getElementsByTagName("div")[0]
            for (i = 0; i < sl; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i
                h.innerHTML = this.innerHTML
                y = this.parentNode.getElementsByClassName("same-as-selected")
                yl = y.length
                for (k = 0; k < yl; k++) {
                  y[k].removeAttribute("class")
                }
                this.setAttribute("class", "same-as-selected")
                break
              }
            }
          })
          b.appendChild(c)
        }
        const dynamicContent = document.getElementById("dynamic_content")
        dynamicContent.textContent = ""
        dynamicContent.appendChild(b)
        dropdownModal.show()
      }
    })
  }

  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
  except the current select box: */
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = []
    x = document.getElementsByClassName("select-items")
    y = document.getElementsByClassName("select-selected")
    xl = x.length
    yl = y.length
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active")
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide")
      }
    }
  }

  /* If the user clicks anywhere outside the select box,
then close all select boxes: */
  document.addEventListener("click", closeAllSelect)
}
