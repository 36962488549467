export {}
const slider: HTMLElement = document.querySelector('.key-technology__chips')
const slides: NodeListOf<Element> = document.querySelectorAll('.key-technology__chips__chip')
let counter: number = 0
const slideWidth: number = slides.length > 0 ? slides[0].clientWidth : 0
let touchStartX: number = 0
let touchEndX: number = 0
let isMouseDown: boolean = false
let mouseX: number = 0

function moveSlideKt(direction: string): void {
  if (direction === 'left') {
    counter--
    if (counter < 0) counter = slides.length - 1
  } else if (direction === 'right') {
    counter++
    if (counter === slides.length) counter = 0
  }

  if( window.innerWidth < 1024) {
    slider.style.transform = `translateX(${-counter * slideWidth}px)`
  }
}

function handleMouseMoveKt(clientX): void {
    const mouseSwipeThreshold: number = 50; // Adjust as needed
    const swipeLength: number = clientX - mouseX
    if (swipeLength > mouseSwipeThreshold) {
        moveSlideKt('left')
        mouseX = clientX
    } else if (swipeLength < -mouseSwipeThreshold) {
        moveSlideKt('right')
        mouseX = clientX
    }
}

function handleSwipeKt(): void {
    const swipeThreshold: number = 50
    const swipeLength: number = touchEndX - touchStartX
    if (swipeLength > swipeThreshold) {
        moveSlideKt('left')
    } else if (swipeLength < -swipeThreshold) {
        moveSlideKt('right')
    }
}

if (slider) {
    slider.addEventListener('touchstart', (event: TouchEvent): void => {
        touchStartX = event.touches[0].clientX
    })

    slider.addEventListener('touchend', (event: TouchEvent): void => {
        touchEndX = event.changedTouches[0].clientX
        handleSwipeKt()
    })

    slider.addEventListener('mousedown', (event: MouseEvent): void => {
        isMouseDown = true
        mouseX = event.clientX
    })

    slider.addEventListener('mousemove', (event: MouseEvent): void => {
        if (isMouseDown) {
          handleMouseMoveKt(event.clientX)
        }
    })

    slider.addEventListener('mouseup', (): void => {
        isMouseDown = false
    })

    slider.addEventListener('mouseleave', (): void => {
        isMouseDown = false
    })
}
