<template>
    <div>
        <div class="container blogs-filters" id="blogs-filters-form">
            <form @submit.prevent="applyFilters('desktop')" class="blogs-filters__search-container">
                <div
                    class="dropdown"
                    :class="checkedCountries.length > 0 ? 'items-checked' : ''"
                >
                    <button
                        class="dropdown-face body-m-regular text-medium-gray"
                        type="button"
                        id="countriesDropdown"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                    >
                        {{ checkedCountries.length > 0 ? checkedCountries[0] : 'Sort by location'}}
                        <div v-if="checkedCountries.length > 1" class="extra-counrties-checked">
                            <span>+{{ checkedCountries.length - 1 }}</span>
                        </div>
                        <img src="/theme/luxoft/assets/images/icons/caret-down-black.svg"/>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="countriesDropdown">
                        <li v-for="(country, key) in allCountries">
                            <label :for="key">{{ country.name }}</label>
                            <input type="checkbox" :id="key" :value="country.name" v-model="checkedCountries">
                        </li>
                    </ul>
                </div>

                <button type="submit" class="btn-normal">
                    Apply
                </button>
            </form>

            <div class="blogs-filters__chips mt-3">
                <template v-for="(country, index) in checkedCountriesCopyForChips">
                    <div class="blogs-filters__chips blogs-filters__chips--chip place-chip">
                        <p>{{ country }}</p>
                        <button @click="(event) => removeCheckedItem('country', index)" ref="countryBtn">
                            <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close search icon">
                        </button>
                    </div>
                </template>

                <div v-show="checkedCountriesCopyForChips.length > 0" class="blogs-filters__chips blogs-filters__chips--chip counter-chip">
                    <button @click="clearFilters()">
                        <p>Clear all</p>
                    </button>
                </div>
            </div>
        </div>

        <Teleport to="body">
            <div
                class="jobs-listing-panels"
                id="mobile-filters-panel"
                ref="jobsMobileFilters"
            >
                <div
                    class="mobile-all-filters"
                    :class="{ 'hidden': currentMobilPanelSection !== 'all-filters' }"
                >
                    <div class="jobs-listing-panels__head main-panel">
                        <p>Filters</p>

                        <button
                            v-show="countriesChipsMobile.length > 0"
                            @click="clearFilters()"
                            class="clear-all-btn"
                        >
                            Clear all
                        </button>

                        <button @click="closePanel">
                            <svg class="close-icon">
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#close-icon"></use>
                            </svg>
                        </button>
                    </div>
                    <div class="jobs-listing-panels__content">
                        <div class="input-filter-wrapper">
                            <button @click="currentMobilPanelSection = 'search-countries'">Countries <i class="arrow"></i> </button>
                        </div>
                        <div class="chips-filter-mobile">
                            <div
                                v-for="(chip, key) in countriesChipsMobile"
                                class="chips-filter-mobile--chip p-chip"
                            >
                                <p>{{ chip }}</p>
                                <button @click="removeChipMobile('countries', key)">
                                    <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close chip icon">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="jobs-listing-panels__close">
                        <button @click="applyFilters" class="btn-normal">Apply</button>
                    </div>
                </div>

                <div
                    class="mobile-single-search-panel"
                    :class="{ 'shown': currentMobilPanelSection === 'search-countries' }"
                >
                    <div class="jobs-listing-panels__head">
                        <p>Countries</p>
                        <button @click="currentMobilPanelSection = 'all-filters'">
                            <svg class="close-icon">
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#close-icon"></use>
                            </svg>
                        </button>
                    </div>
                    <div class="jobs-listing-panels__content">
                        <div class="filter-input-search">
                            <img src="/theme/luxoft/assets/images/icons/search-gray.svg" alt="search icon">
                            <input type="text" name="country-search" placeholder="Search countries" v-model="countryStr">
                            <button @click="countryStr = ''">
                                <img src="/theme/luxoft/assets/images/icons/close-chip.svg" width="24" height="24" alt="close search icon">
                            </button>
                        </div>
                    </div>
                    <div class="jobs-listing-panels__list checkbox-list">
                        <div class="filter-search-results mt-1">
                            <ul class="">
                                <li v-for="(country, key) in mobileFilteredCountries">
                                    <label :for="`check-${key}`">{{ country.name }}</label>
                                    <input type="checkbox" :id="`check-${key}`" :value="country.name" v-model="checkedCountries">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="checkedCountries.length > 0" class="jobs-listing-panels__close">
                        <button @click="applyChipsMobile('countries')" class="btn-normal">Apply</button>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import useBlogFiltersFns from '../functions/blogsFilters'
import useBlogMobileFiltersFns from '../functions/blogMobileFilters'

export default {
    props: {
        countries: {
            type: Array,
            required: true,
            default: () => [],
        },
        topics: {
            type: Array,
            required: true,
            default: () => [],
        }
    },
    setup(props) {
        const urlParams = new URLSearchParams(window.location.search)
        const checkedCountries = ref([])
        const countriesChipsMobile = ref([])
        const allCountries = ref(props.countries)

        const {
            getElementsFromUrl,
            removeCheckedItem,
            applyFilters,
            clearFilters,
            updatePaginationLiks,
            chipsToDisplay,
            checkedCountriesCopyForChips,
            } = useBlogFiltersFns(
                urlParams,
                checkedCountries,
                countriesChipsMobile
            )

        const {
            closePanel,
            applyChipsMobile,
            removeChipMobile,
            mobileFiltersPanel,
            currentMobilPanelSection,
            mobileFilteredCountries,
            countryStr
              } = useBlogMobileFiltersFns(
                allCountries,
                checkedCountries,
                countriesChipsMobile
            )

        onMounted(() => {
            getElementsFromUrl()
            updatePaginationLiks()
        })

        return {
            applyFilters,
            clearFilters,
            removeCheckedItem,
            closePanel,
            applyChipsMobile,
            removeChipMobile,
            chipsToDisplay,
            allCountries,
            checkedCountries,
            countriesChipsMobile,
            checkedCountriesCopyForChips,
            mobileFiltersPanel,
            currentMobilPanelSection,
            mobileFilteredCountries,
            countryStr
        }
    }
}
</script>
