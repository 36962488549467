import Splide, {PaginationData, PaginationItem} from "@splidejs/splide"

if (document.getElementById("splider-upcoming-events")) {
  const upcomingEventsCarousel: NodeListOf<Element> = document.querySelectorAll(
    "#splider-upcoming-events.splide"
  )

  upcomingEventsCarousel.forEach((carouselElement: any): void => {
    const carouselItems: NodeListOf<Element> = carouselElement.querySelectorAll(".splide__slide")

    const isLoopEnabled: boolean = carouselItems.length > 1
    const splide: Splide = new Splide(carouselElement, {
      type: isLoopEnabled ? "loop" : "slide",
      fixedWidth: "84%",
      gap: "15px",
      drag: true,
      arrows: false,
    })

    splide.on("pagination:mounted", function (data: PaginationData): void {
      data.list.classList.add("splide__pagination--custom")

      data.items.forEach(function (item: PaginationItem): void {
        item.button.classList.add("splide__pagination--custom__item")
      })
    })

    splide.mount()
  })
}

if (document.getElementById("splider-past-events")) {
  const pastEventsCarousel: NodeListOf<Element> = document.querySelectorAll(
    "#splider-past-events.splide"
  )

  pastEventsCarousel.forEach((carouselElement: any): void => {
    const carouselItems: NodeListOf<Element> = carouselElement.querySelectorAll(".splide__slide")

    const isLoopEnabled: boolean = carouselItems.length > 1
    const splide: Splide = new Splide(carouselElement, {
      type: isLoopEnabled ? "loop" : "slide",
      fixedWidth: "84%",
      gap: "15px",
      drag: true,
      arrows: false,
    })

    splide.on("pagination:mounted", function (data: PaginationData): void {
      data.list.classList.add("splide__pagination--custom")

      data.items.forEach(function (item: PaginationItem): void {
        item.button.classList.add("splide__pagination--custom__item")
      })
    })

    splide.mount()
  })
}

if (document.getElementById("photo-gallery-splider")) {
  const spliderLocationLocations: NodeListOf<Element> = document.querySelectorAll(
    "#photo-gallery-splider.splide"
  )

  spliderLocationLocations.forEach((item: any): void => {
    const splide: Splide = new Splide(item, {
      type: 'loop',
      gap: "20px",
      drag: true,
      arrows: true,
      perMove: 1,
      perPage: 1,
    })

    splide.on("pagination:mounted", function (data: PaginationData): void {
      data.list.classList.add("splide__pagination--custom")

      data.items.forEach(function (item: PaginationItem): void {
        item.button.classList.add("splide__pagination--custom__item")
      })
    })

    splide.mount()
  })
}
