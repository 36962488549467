<template>
    <a :href="`/jobs/${job.slug}`" class="jobs__list__job">
        <div class="jobs__list__job__details">
            <h2 class="subtitle-l text-rich-black">
                {{ job.title }}
            </h2>
            <p class="body-m-regular text-dark-gray">
                {{ job.specialization }}
            </p>
            <div class="jobs__list__job__details__tags">
                <div class="jobs__list__job__details__tags__location text-rich-black">
                    <img class="pin-icon" src="/theme/luxoft/assets/images/icons/grey-pin.svg" alt="pin-icon">
                    <p class="body-s-regular">
                        {{ job.city }}
                    </p>
                    <img src="/theme/luxoft/assets/images/icons/grey-dot.svg" alt="grey-dot icon">
                    <p class="body-s-regular">
                        {{ job.country }}
                    </p>
                </div>

                <div v-if="job.hot === 'YES'" class="jobs__list__job__details__tags__hotjob">
                    <p class="body-s-regular text-dark-gray">
                        Hot jobs
                    </p>
                </div>

                <div v-if="job.relocation_friendly === 'YES'" class="jobs__list__job__details__tags__hotjob">
                    <p class="body-s-regular text-dark-gray">
                        Relocation friendly
                    </p>
                </div>

            </div>
        </div>
        <div class="jobs__list__job__buttons">
            <div class="jobs__list__job__buttons__icon job-bookmark job-bookmark-list" :data-job="getJobData(job)">
                <div class="save-job-icon"></div>
            </div>
            <div class="jobs__list__job__buttons__icon">
                <button class="share-job-btn" :id="index">
                    <img src="/theme/luxoft/assets/images/icons/vertical-dots.svg" alt="vertical dots" :data-jobid="`/jobs/${job.slug}`">
                </button>
            </div>
            <div class="share-job-options" :id="`share-${index}`">
                <div class="share-job-options__desktop">
                    <button class="share-job-option social-share" data-network="refer" data-source="share-job-desktop-listing-page" :data-title="job.title" :data-link="`https://referral.luxoft.com/referrer/recommendations/new?vacancy[name]=${encodeURIComponent(escapeHtmlEntities(job.title))}&vacancy[code]=${encodeURIComponent(escapeHtmlEntities(job.vrPkey))}&vacancy[specialization]=${encodeURIComponent(escapeHtmlEntities(job.specialization))}&vacancy[country]=${encodeURIComponent(escapeHtmlEntities(job.country))}&vacancy[city]=${encodeURIComponent(escapeHtmlEntities(job.city))}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--refer">
                            <img src="/theme/luxoft/assets/images/icons/user.svg" alt="user icon">
                        </div>
                        Refer a friend
                    </button>
                    <button class="share-job-option social-share" data-network="fb" data-source="share-job-desktop-listing-page" :data-title="job.title" :data-link="`/jobs/${job.slug}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--facebook">
                            <svg>
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#facebook-icon"></use>
                            </svg>
                        </div>
                        Facebook
                    </button>
                    <button class="share-job-option social-share" data-network="twitter" data-source="share-job-desktop-listing-page" :data-title="job.title" :data-link="`/jobs/${job.slug}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--twitter">
                            <svg>
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#twitter-icon"></use>
                            </svg>
                        </div>
                        Twitter
                    </button>
                    <button class="share-job-option social-share" data-network="linkedin" data-source="share-job-desktop-listing-page" :data-title="job.title" :data-link="`/jobs/${job.slug}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--linkedin">
                            <svg>
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#linkedin-icon"></use>
                            </svg>
                        </div>
                        Linkedin
                    </button>
                    <button class="share-job-option social-share" data-network="mail" data-source="share-job-desktop-listing-page" :data-title="job.title" :data-link="`/jobs/${job.slug}`">
                        <div class="share-job-option__icon-wrapper share-job-option__icon-wrapper--mail">
                            <svg>
                                <use xlink:href="/theme/luxoft/assets/images/icons/icons.svg#mail-icon"></use>
                            </svg>
                        </div>
                        Mail
                    </button>
                </div>
                <div class="share-job-options__mobile">
                    <button class="share-job-option job-bookmark job-bookmark-list" :data-job="getJobData(job)">
                        <img src="/theme/luxoft/assets/images/icons/favorite-purple.svg" alt="favorite icon">
                        Add to favorites
                    </button>
                    <button class="share-job-option social-share" data-network="refer" data-source="share-job-desktop-listing-page" :data-title="job.title" :data-link="`https://referral.luxoft.com/referrer/recommendations/new?vacancy[name]=${encodeURIComponent(escapeHtmlEntities(job.title))}&vacancy[code]=${encodeURIComponent(escapeHtmlEntities(job.vrPkey))}&vacancy[specialization]=${encodeURIComponent(escapeHtmlEntities(job.specialization))}&vacancy[country]=${encodeURIComponent(escapeHtmlEntities(job.country))}&vacancy[city]=${encodeURIComponent(escapeHtmlEntities(job.city))}`">
                        <img src="/theme/luxoft/assets/images/icons/refer.svg" alt="refer icon">
                        Refer a friend
                    </button>
                    <button class="share-job-option mobile-share-btn" :data-title="job.title" :data-link="`/jobs/${job.slug}`">
                        <img src="/theme/luxoft/assets/images/icons/share.svg" alt="share icon">
                        Share
                    </button>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: {
        job: {
            type: Object,
            required: true,
            default: () => [],
        },
        index: {
            type: Number,
            required: true,
            default: 0,
        }
    },
    setup(props) {
        const getJobData = (job) => {
            const data = {
                'title': job.title,
                'subtitle': job.specialization,
                'city': job.city,
                'location': job.country,
                'url': `/jobs/${job.slug}`
            }

            return JSON.stringify(data)
        }

        const escapeHtmlEntities = (str) => {
            return String(str).replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#39;')
        }

        return {
            escapeHtmlEntities,
            getJobData
        }
    }
}
</script>


