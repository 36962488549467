<template>
    <h1 class="container page-title job-page-title">
        <a class="container job-back-btn btn-transparent" href="/jobs">
            <svg class="facebook-icon">
                <use
                    xlink:href="/theme/luxoft/assets/images/icons/icons.svg#chevron-left-icon"
                ></use>
            </svg>
            <p class="body-s-regular">BACK to jobs</p></a
        >
    </h1>
    <div class="bg-gradient"></div>

    <div id="whiteSection" class="white-background thank-you">
        <div class="container thank-you__job">
            <div class="thanks-wrapper">
                <img src="/theme/luxoft/assets/images/thank-you.svg" alt="Thank you"/>
                <h4>Thank you for your application!</h4>
                <p>Please check out our hiring process <a href="/hiring-process">here</a>.</p>
            </div>
        </div>
        <div class="container cta-section cta-section--light-gray">
            <div class="cta-section__cta-banner">
                <h3>Curious about the benefits of working with us?</h3>
                <a class="btn-normal" href="/about">LEARN MORE</a>
            </div>
        </div>
    </div>
</template>
<script>

export default {

}
</script>
