import {computed, ref} from 'vue'

const mobileFiltersPanel = ref(null)
const overlay = document.querySelector("#overlay-mobile")
const currentMobilPanelSection = ref('all-filters')
const countryStr = ref('')

const useBlogMobileFiltersFns = (
  allCountries,
  checkedCountries,
  countriesChipsMobile,
)=> {

  const closePanel = () => {
    overlay.classList.remove('overlay-show')
    mobileFiltersPanel.value.classList.remove('show')
  }

  const mobileFilteredCountries = computed( () => {
    return allCountries.value.filter(item => item.name.toLowerCase().includes(countryStr.value.toLowerCase()))
  })

  const applyChipsMobile = (type) => {
    if (type === 'countries') {
      countriesChipsMobile.value = [...new Set([...countriesChipsMobile.value ,...checkedCountries.value])]
    }

    currentMobilPanelSection.value = 'all-filters'
  }

  const removeChipMobile = (type, index) => {
    if (type === 'countries') {
      countriesChipsMobile.value.splice(index, 1)
      checkedCountries.value.splice(index, 1)
    }
  }

  return {
    closePanel,
    applyChipsMobile,
    removeChipMobile,
    mobileFiltersPanel,
    currentMobilPanelSection,
    mobileFilteredCountries,
    countryStr,
  }
}

export default useBlogMobileFiltersFns
