import { createApp } from "vue/dist/vue.esm-bundler"
import VueAwesomePaginate from "vue-awesome-paginate"
import "vue-awesome-paginate/dist/style.css"
import "@splidejs/splide/css/core"
import {getCurrentPathComponent, routes} from "./config/router"

interface AppComponent {
    name: string | undefined;
    component: object | undefined;
}

const { name = undefined, component = undefined }: AppComponent = getCurrentPathComponent(routes)

if (name) {
  const app = createApp({})
  app.component(name, component)
  app.use(VueAwesomePaginate)
  app.mount("#app")
}
