import AnchorsHeader from "../vue/components/AnchorsHeader.vue"
import HomeSearch from "../vue/components/HomeSearch.vue"
import SavedJobs from "../vue/views/SavedJobs.vue"
import Search from "../vue/views/Search.vue"
import JobsFilters from "../vue/views/JobsFilters.vue"
import ThankYouJob from "../vue/views/ThankYouJob.vue"
import ThankYouEvent from "../vue/views/ThankYouEvent.vue"
import BlogsFilters from "../vue/views/BlogsFilters.vue"
import EmbeddedJobsListing from "../vue/views/EmbeddedJobsListing.vue"
import LoGeekMagazine from "../vue/views/LoGeekMagazine.vue"

export const routes = {
  "/locations": {
    name: "AnchorsHeader",
    component: AnchorsHeader,
  },
  "/": {
    name: "HomeSearch",
    component: HomeSearch,
  },
  "/saved-jobs": {
    name: "SavedJobs",
    component: SavedJobs,
  },
  "/search": {
    name: "Search",
    component: Search,
  },
  "/jobs": {
    name: "JobsFilters",
    component: JobsFilters,
  },
  "/blog": {
    name: "BlogsFilters",
    component: BlogsFilters,
  },
  "Regex:<ThankYouJob>": {
    regex: /\/jobs\/([a-zA-Z0-9_-]+)-([0-9]+)\/application-confirmation/,
    name: "ThankYouJob",
    component: ThankYouJob
  },
  "Regex:<Jobs>": {
    regex: /\/(jobs\/[a-zA-Z0-9-]+)/,
    name: "JobsFilters",
    component: JobsFilters
  },
  "Regex:<ThankYouEvent>": {
    regex: /\/events\/([a-zA-Z0-9_-]+)\/registration-thank-you/,
    name: "ThankYouEvent",
    component: ThankYouEvent
  },
  "Regex:<EmbeddedJobsListing>": {
    regex: /\/(adas-jobs|together-for-better-financial-applications|from-ai-to-automotive-your-future-awaits-with-dxc-luxoft-job-opportunities)/,
    name: "EmbeddedJobsListing",
    component: EmbeddedJobsListing,
  },
  "/logeek-tech-magazine": {
    name: "LoGeekMagazine",
    component: LoGeekMagazine,
  }
}

export const getCurrentPathComponent = (routes, path = window.location.pathname) => {
  const route = routes[path]

  if (route) {
    return {...route}
  }

  for (let index in routes) {
    if (!routes[index]?.regex) {
      continue
    }

    if (window.location.pathname.match(routes[index].regex) === null) {
      continue
    }

    return { name: routes[index].name, component: routes[index].component }
  }

  return {}
}
