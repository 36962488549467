import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Splide, {PaginationData, PaginationItem} from "@splidejs/splide"

gsap.registerPlugin(ScrollTrigger)

function initializeMentoringSplide(carouselElement): void {
  const splide: Splide = new Splide(carouselElement, {
    type: "loop",
    fixedWidth: "90%",
    height: "232px",
    gap: "15px",
    drag: true,
    arrows: false,
  })

  splide.on("pagination:mounted", function (data: PaginationData): void {
    data.list.classList.add("splide__pagination--custom")

    data.items.forEach(function (item: PaginationItem): void {
      item.button.classList.add("splide__pagination--custom__item")
    })
  })

  splide.on("ready", function (): void {
    // Fixes Safari problem with svg rendering
    const svgsInCarousel = carouselElement.querySelectorAll('svg')
    svgsInCarousel.forEach((svg): void => {
      // Toggle the 'display' property to trigger a redraw
      svg.style.display = "none"
      setTimeout((): void => {
        svg.style.display = "block"
      }, 100)
    })
  })

  splide.mount()
}

function initializeHeaderImgsSplide(carouselElement): void {
  const splide: Splide = new Splide(carouselElement, {
    fixedWidth: "80%",
    height: "406px",
    gap: "15px",
    drag: true,
    arrows: false,
  })

  splide.on("pagination:mounted", function (data: PaginationData): void {
    data.list.classList.add("splide__pagination--custom")

    data.items.forEach(function (item: PaginationItem): void {
      item.button.classList.add("splide__pagination--custom__item")
    })
  })

  splide.on("ready", function (): void {
    // Fixes Safari problem with svg rendering
    const svgsInCarousel = carouselElement.querySelectorAll("svg")
    svgsInCarousel.forEach((svg) => {
      // Toggle the 'display' property to trigger a redraw
      svg.style.display = "none"
      setTimeout(() => {
        svg.style.display = "block"
      }, 100)
    })
  })

  splide.mount()
}

function initializeInfoBannerSplide(carouselElement): void {
  const splide: Splide = new Splide(carouselElement, {
    fixedWidth: "90%",
    height: "140px",
    gap: "15px",
    drag: true,
    arrows: false,
  })

  splide.on("pagination:mounted", function (data: PaginationData): void {
    data.list.classList.add("splide__pagination--custom")

    data.items.forEach(function (item): void {
      item.button.classList.add("splide__pagination--custom__item")
    })
  })

  splide.on("ready", function (): void {
    // Fixes Safari problem with svg rendering
    const svgsInCarousel = carouselElement.querySelectorAll('svg')
    svgsInCarousel.forEach((svg): void => {
      // Toggle the 'display' property to trigger a redraw
      svg.style.display = 'none'
      setTimeout((): void => {
        svg.style.display = 'block'
      }, 100)
    })
  })

  splide.mount()
}

let twoColumnsContainer: any = document.getElementById('twoColumnsTextAndCards')

if (twoColumnsContainer) {
  twoColumnsContainer = twoColumnsContainer.parentNode
}

const twoColumnsTextAndCards: HTMLElement = document.getElementById('twoColumnsTextAndCards')
const leftCol: Element = document.querySelector('.about-us-first__two-col-scroll-container__right-col')

function getDistanceFromContainerTop(container: HTMLElement, element: Element): number {
  const containerRect: DOMRect = container.getBoundingClientRect()
  const elementRect: DOMRect = element.getBoundingClientRect()
    return Math.abs(elementRect.top - containerRect.top)
}

const container: HTMLElement = document.getElementById('container-to-get')
const cardsSections: Element[] = Array.from(document.querySelectorAll('.about-us-first__two-col-scroll-container__right-col__cards-section'))

cardsSections.forEach((element: Element): void => {
  getDistanceFromContainerTop(container, element)
})

if (twoColumnsTextAndCards) {
  if (window.innerWidth >= 1440) {
    gsap.to(".about-us-first__two-col-scroll-container__right-col", {
      scrollTrigger: {
        trigger: "#pinned-element",
        start: "top-=150px top",
        end: "bottom+=2000 top",
        scrub: 0,
        pin: twoColumnsContainer,
      },
      y: -leftCol.clientHeight + 400,
      ease: "none",
    })

    gsap.to(".about-us-first__two-col-scroll-container__left-col", {
      scrollTrigger: {
        trigger: "#cards-section-2",
        start: "0 75%",
        end: "700 75%",
        scrub: 0,
      },
      y: -340,
      ease: "none",
    })

    gsap.to(".about-us-first__two-col-scroll-container__left-col", {
      scrollTrigger: {
        trigger: "#cards-section-3",
        start: "240 75%",
        end: "940 75%",
        scrub: 0,
      },
      y: -680,
      ease: "none",
      immediateRender: false,
    })

    gsap.to("#left-title-text-1", {
      scrollTrigger: {
        trigger: "#cards-section-1",
        start: "0 75%",
        end: "700 75%",
        scrub: 0,
      },
      fontSize: 45,
      ease: "none",
    })

    gsap.to("#left-title-text-2", {
      scrollTrigger: {
        trigger: "#cards-section-2",
        start: "0 75%",
        end: "700 75%",
        scrub: 0,
      },
      fontSize: 45,
      ease: "none",
    })

    gsap.to("#left-title-text-1", {
      scrollTrigger: {
        trigger: "#cards-section-2",
        start: "0 75%",
        end: "700 75%",
        scrub: 0,
      },
      fontSize: 32,
      immediateRender: false,
      ease: "none",
    })

    gsap.to("#left-title-text-3", {
      scrollTrigger: {
        trigger: "#cards-section-3",
        start: "240 75%",
        end: "940 75%",
        scrub: 0,
      },
      fontSize: 45,
      ease: "none",
    })

    gsap.to("#left-title-text-2", {
      scrollTrigger: {
        trigger: "#cards-section-3",
        start: "240 75%",
        end: "940 75%",
        scrub: 0,
      },
      fontSize: 32,
      immediateRender: false,
      ease: "none",
    })
  } else if (window.innerWidth < 1440 && window.innerWidth >= 1024) {
    gsap.to(".about-us-first__two-col-scroll-container-tablet", {
      scrollTrigger: {
        trigger: "#tablet-pinned-element",
        pin: twoColumnsContainer,
        start: "top 30px",
        end: "bottom top",
        scrub: 0,
      },
      y:
        -document.querySelector(
          ".about-us-first__two-col-scroll-container-tablet"
        ).clientHeight + 800,
      ease: "none",
    })
  } else if (window.innerWidth < 1024) {
    const panels: NodeListOf<Element> = twoColumnsContainer.querySelectorAll(".collapsible-content")
    const carouselElements: NodeListOf<Element> = twoColumnsContainer.querySelectorAll(
      ".about-us-first__two-col-scroll-container-mobile__panels__panel__carousel"
    )

    panels.forEach((accordion: Element, index: number): void => {
      let initialized: boolean = false // Flag to track initialization
      const carouselElement: Element = accordion.querySelector(".splide") // Find the carousel

      if (carouselElement) {
        if (index === 0) {
          // Explicitly initialize the first carousel at page load
          initializeMentoringSplide(carouselElement)
          initialized = true
          carouselElement.classList.add("initialized")
        }
      }
    })

    const buttons: NodeListOf<Element> = twoColumnsContainer.querySelectorAll('.collapsible-button')
    const contents: NodeListOf<Element> = twoColumnsContainer.querySelectorAll('.collapsible-content')

    buttons.forEach((button: Element): void => {
      button.addEventListener("click", (): void => {
        contents.forEach((content: Element): void => {
          if (content.id === button.getAttribute("data-target")) {
            content.classList.add("active")
            const contentId: any = content.id.split("-")[1]
            const carouselToSwitch: Element = carouselElements[contentId - 1]
            if (!carouselToSwitch.classList.contains('initialized')) {
              carouselToSwitch.classList.add('initialized')
              initializeMentoringSplide(carouselToSwitch)
            }
          } else {
            content.classList.remove('active')
          }
        })

        buttons.forEach((btn: Element): void => {
          if (btn !== button) {
            btn.classList.remove('active')
          }
        })

        button.classList.add('active')
      })
    })

    // Show the first content by default
    contents[0].classList.add('active')
    buttons[0].classList.add('active')
  }
}

const headerImgsCarousel: HTMLElement = document.getElementById("splider-about-us-header")

if (headerImgsCarousel) {
  if (window.innerWidth < 1024) {
    initializeHeaderImgsSplide(headerImgsCarousel)
  }
}

const benefitsSection: HTMLElement = document.getElementById('benefits')
if (benefitsSection) {
  if (window.innerWidth < 1024) {
    const expandButton: HTMLElement = document.getElementById('expand-button')
    const benefitsCards: HTMLElement = document.querySelector('#benefits div')

    if (benefitsCards.childElementCount <= 7) {
      expandButton.style.display = 'none'
      benefitsCards.style.height = 'auto'
    } else {
      benefitsCards.classList.add('more-than-6-children')
    }
    expandButton.addEventListener('click', (): void => {
      benefitsCards.classList.toggle('compressed')
    })
  }
}

const infoBannerSplide: HTMLElement = document.getElementById('splider-about-us-info-banner')

if (infoBannerSplide && window.innerWidth < 1024) {
  initializeInfoBannerSplide(infoBannerSplide)
}
