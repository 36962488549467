export {}
declare const bootstrap: any
declare global {
    interface Window {
        fbAsyncInit: () => void
    }
}

window.fbAsyncInit = (): void => {
    FB.init({
        appId            : '2199632733671618',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v19.0'
    })
}

(function(d, s, id): void {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'))

const tooltipTriggerList: Element[] = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
const tooltipList: bootstrap.Tooltip[] = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
