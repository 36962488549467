<template>
    <h1 class="container page-title job-page-title">
        Your saved jobs
        <a class="container job-back-btn btn-transparent" href="/jobs">
            <svg class="facebook-icon">
                <use
                    xlink:href="/theme/luxoft/assets/images/icons/icons.svg#chevron-left-icon"
                ></use>
            </svg>
            <p class="body-s-regular">Back to jobs</p></a
        >
    </h1>
    <div class="bg-gradient"></div>

    <div id="whiteSection" class="white-background">
        <div class="container jobs">
            <div v-if="bookmarks.length" class="jobs__list">
                <SingleSavedJob
                    v-for="(bookmark, key) in bookmarks"
                    :key="index"
                    :bookmark="bookmark"
                    :index="key"
                    @bookmark-clicked="loadBookmarks"
                />
            </div>
            <div v-else class="jobs__no-vacancies text-center">
                <img src="/theme/luxoft/assets/images/icons/server-not-found-icon.svg" alt="server not found icon"/>
                <p class="mt-2 ">There are no saved jobs yet.</p>
            </div>
        </div>
    </div>
</template>
<script>
import SingleSavedJob from "../components/SingleSavedJob.vue";

export default {
    components: {
        SingleSavedJob
    },
    data() {
        return {
            bookmarks: {}
        }
    },
    mounted() {
        this.loadBookmarks()
    },
    methods: {
        loadBookmarks() {
            this.bookmarks = JSON.parse(localStorage.bookmarks) || []
        }
    },
}
</script>
